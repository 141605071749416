import React, { useEffect, useState } from 'react';
import { PageHeader, Table, Button, Tag, Space, Popconfirm, Input, message, Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';
import reportsConstants from 'redux/reports/constants';
import usersConstants from 'redux/users/constants';
import { fetchReports, setLoadingReports, blockReportedUser, unblockReportedUser, setReports, deleteStorie, getDetailReport } from 'redux/reports-stories/actions';
import usersServices from 'redux/users/services';
import store from 'redux/rootStore';

const { PAGE_SIZE_OPTIONS, PAGE_SIZE } = reportsConstants;
const { BUSINESS_STATUS, STATUS, TYPE, REGULAR_FILTERS, BUSINESS_FILTERS } = usersConstants;
const { getStatusColor, getBusinessStatusColor } = usersServices;
const { Search } = Input;

interface ReportsData {
    content?: String,
    owner?: String,
    report_totals?: String,
    storie_id?: String,
}

const fetchReportsHandler = () => {
  const { dispatch } = store;
  dispatch(setLoadingReports(true));
  fetchReports().then((data: any) => {
    if (data?.data?.stories_with_reports) {
      dispatch(setReports(data.data.stories_with_reports));
      dispatch(setLoadingReports(false));
    }
  });
};

function Reports() {

  const { loading, data } = useSelector((state: any) => state.storiesReports);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [dataSelected, setDataSelected] = useState<ReportsData>({});
  const [dataDetail, setDataDetail] = useState({
    load: false,
    data: [],
  });

  useEffect(() => {
    fetchReportsHandler();
  }, []);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const deleteStorieAction = (storie_id: any) => {
    setModalVisible(false);
    deleteStorie(storie_id).then((response) => {
      if (response.status === 200 && response.data.remove) {
        fetchReportsHandler();
      } else {
        message.error('Could not delete the storie');
      }
    });
  }

  const onSearch = (value: any) => {
    if (!value.length) fetchReportsHandler();
    const lowerCaseValue = value.toLowerCase();
    const filteredData = data.filter(
      (row: any) =>
        row.user_receiver.toLowerCase().includes(lowerCaseValue) ||
        row.user_whistleblower.toLowerCase().includes(lowerCaseValue) ||
        row.content.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };

  const onChange = (e: any) => {
    const value = e.target.value;
    if (!value.length) fetchReportsHandler();
  };

  const handleCancel = () => {
    setModalVisible(false);
  }

  const viewDetailReports = (item: object) => {
      setDataSelected(item);
      setModalVisible(true);
  }
  
  useEffect(() => {
    if (isModalVisible) {
      getDetailReport(dataSelected.storie_id).then((response) => {
          setDataDetail({
            load: true,
            data: response.data,
          });
      });
    } else {
      setDataDetail({ load: false, data: [] });
    }
  },[isModalVisible])

  const Actions = ({ data }: any) => {
    return (
      <Space>
         <Button type='primary' onClick={() => viewDetailReports(data)}>Show detail</Button>
         <Popconfirm
            title='your are sure a want delete this storie?'
            okText='Yes'
            cancelText='No'
            //disabled={disabledUnblock}
            onConfirm={() => deleteStorieAction(data.storie_id)}
           >
            <Button type='primary' danger>Delete</Button>
          </Popconfirm>
      </Space>
    );
  };
  
  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      onFilter: (value: any, record: any) => record.owner_storie.indexOf(value) === 0,
      sorter: (a: any, b: any) => {
        if (a.owner_storie.toLowerCase() < b.owner_storie.toLowerCase()) {
          return -1;
        }
        if (a.owner_storie.toLowerCase() > b.owner_storie.toLowerCase()) {
          return 1;
        }
        if (a.owner_storie.toLowerCase() < b.owner_storie.toLowerCase()) {
          return -1;
        }
        if (a.owner_storie.toLowerCase() > b.owner_storie.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      render: (text: any, record: any) => {
        return record.owner_storie;
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'report_totals',
      key: 'report_totals',
      onFilter: (value: any, record: any) => record.report_totals.indexOf(value) === 0,
      sorter: (a: any, b: any) => parseInt(a.report_totals) - parseInt(b.report_totals),
      render: (text: any, record: any) => record.report_totals,
      width: '10%',
    },
    {
      title: 'Content storie',
      dataIndex: 'content',
      key: 'content',
      onFilter: (value: any, record: any) => record.content.indexOf(value) === 0,
      sorter: (a: any, b: any) => parseInt(a.content) - parseInt(b.content),
      render: (text: any, record: any) => record.content,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => <Actions data={record} />,
      fixed: 'right',
      width: 200,
      align: 'right'
    },
  ];


  const columnsDetail: any = [
    {
      title: 'Reported by',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      onFilter: (value: any, record: any) => record.user_whistleblower.indexOf(value) === 0,
      sorter: (a: any, b: any) => {
        if (a.user_whistleblower.toLowerCase() < b.user_whistleblower.toLowerCase()) {
          return -1;
        }
        if (a.user_whistleblower.toLowerCase() > b.user_whistleblower.toLowerCase()) {
          return 1;
        }
        if (a.user_whistleblower.toLowerCase() < b.user_whistleblower.toLowerCase()) {
          return -1;
        }
        if (a.user_whistleblower.toLowerCase() > b.user_whistleblower.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      render: (text: any, record: any) => {
        return record.user_whistleblower;
      },
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      onFilter: (value: any, record: any) => record.comment.indexOf(value) === 0,
      sorter: (a: any, b: any) => parseInt(a.comment) - parseInt(b.comment),
      render: (text: any, record: any) => record.comment,
      width: '50%',
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a: any, b: any) => {
        const firstTime = new Date(a.created_at).getTime();
        const secondTime = new Date(b.created_at).getTime();
        return secondTime - firstTime;
      },
      render: (text: any, record: any) => {
        const date = new Date(record.created_at);
        return date.toString();
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title='Stories reports'
        ghost={false}
        extra={[<Search placeholder='Search storie report' enterButton allowClear onSearch={onSearch} style={{ width: 350 }} onChange={onChange} />]}
      />
      <div className='content_container'>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          loading={loading}
          //scroll={{ x: 1500 }}
        />
      </div>
      <Modal 
        title="Detail storie report"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={() => console.log()}
        width={1000}
        footer={[
           <Popconfirm
            title='your are sure a want delete this storie?'
            okText='Yes'
            cancelText='No'
            //disabled={disabledUnblock}
            onConfirm={() => deleteStorieAction(dataSelected.storie_id)}
           >
            <Button disabled={!dataDetail.load} type='primary' danger>Delete</Button>
           </Popconfirm>,
          <Button onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        {dataDetail.load ?
         <Table
          columns={columnsDetail}
          dataSource={dataDetail.data}
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          loading={loading}
        /> : 
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 100 }}>
          <Spin/>
        </div>}
      </Modal>
    </div>
  );
}

export default Reports;
