import api from '../../helpers/api';

const recommendationsServices = {
  fetchAll: () => {
    return api.get('/recommendations');
  },
  accept: (recommendations: any) => {
    return api.put(`/recommendations/accept`, { recommendations });
  },
  cancel: (recommendations: any) => {
    return api.put(`/recommendations/cancel`, { recommendations });
  },
  edit: (recommendation: any) => {
    return api.put(`/recommendations`, { recommendation });
  },
};

export default recommendationsServices;
