import domainStatsService from './services';
import domainStatsConstants from './constants';

const { SET_LOADING_DOMAINSTATS } = domainStatsConstants;

export const setLoadingDomainStats = (isLoadingDomainStats: boolean) => ({
  type: SET_LOADING_DOMAINSTATS,
  isLoadingDomainStats,
});

export const fetchDomainStats = (query?: any) => {
  return domainStatsService.fetch().catch(() => []);
};
