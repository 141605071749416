import React, { useEffect, useState } from 'react';
import { PageHeader, Table, Button, Tag, Space, Popconfirm, Input, message, Modal } from 'antd';
import { useSelector } from 'react-redux';
import reportsConstants from 'redux/reports/constants';
import usersConstants from 'redux/users/constants';
import { fetchStories, setLoadingReports, setReports, deleteStorie, updateStorie} from 'redux/stories/actions';
import usersServices from 'redux/users/services';
import store from 'redux/rootStore';
import ModalForm from './modalForm';


const { PAGE_SIZE_OPTIONS, PAGE_SIZE } = reportsConstants;
const { Search } = Input;


const deleteStorieAction = (storie_id: String) => {
  deleteStorie(storie_id).then((response) => {
    if (response.status === 200 && response.data.remove) {
      fetchReportsHandler();
    } else {
      message.error('Could not delete the storie');
    }
  });
}


const fetchReportsHandler = () => {
  const { dispatch } = store;
  dispatch(setLoadingReports(true));
  fetchStories().then((data: any) => {
    if (data?.data?.stories) {
      dispatch(setReports(data.data.stories));
      dispatch(setLoadingReports(false));
    }
  });
};

function Stories() {
  const { loading, data } = useSelector((state: any) => state.stories);
  const [filteredData, setFilteredData] = useState([]);
  const [modalForm, setModalForm] = useState({
    type: 'I',
    open: false,
    data: null,
  });

  useEffect(() => {
    fetchReportsHandler();
  }, []);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const editConfirm = (data: object) => {
    updateStorie(data).then((response) => {
      if (response.status === 200) {
        setModalForm({
          type: 'M',
          open: false,
          data: null,
        });
        fetchReportsHandler();
      } else {
        message.error('Could not update the storie');
      }
    })
  }

  const onSearch = (value: any) => {
    if (!value.length) fetchReportsHandler();
    const lowerCaseValue = value.toLowerCase();
    const filteredData = data.filter(
      (row: any) =>
        row.name.toLowerCase().includes(lowerCaseValue) ||
        row.content.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };

  const onChange = (e: any) => {
    const value = e.target.value;
    if (!value.length) fetchReportsHandler();
  };

  const openModal = (type: any, data: any) => {
    setModalForm({
      type,
      open: true,
      data: data,
    });
  }

  const closeModal = () => {
      setModalForm({
        ...modalForm,
        open: false,
      });
  }

  const Actions = ({ data }: any) => {
    return (
      <Space>
         <Button type='primary' onClick={() => openModal('M', data)}>Edit</Button>
         <Popconfirm
            title='your are sure a want delete this storie?'
            okText='Yes'
            cancelText='No'
            //disabled={disabledUnblock}
            onConfirm={() => deleteStorieAction(data.id)}
           >
            <Button type='primary' danger>Delete</Button>
         </Popconfirm>
      </Space>
    );
 };

 const columns: any = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
    sorter: (a: any, b: any) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    render: (text: any, record: any) => {
      return record.name;
    },
    width: '20%',
  },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
    onFilter: (value: any, record: any) => record.content.indexOf(value) === 0,
    sorter: (a: any, b: any) => parseInt(a.content) - parseInt(b.content),
    render: (text: any, record: any) => record.content,
    width: '30%',
  },
  {
    title: 'Text color',
    dataIndex: 'color_text',
    key: 'color_text',
    width: '10%',
    render: (text: any, record: any) => {
       return <div style={{ height: 25, width: 25, border: '1px solid grey', backgroundColor: record.color_text }}></div>
    }
  },
  {
    title: 'Background color',
    dataIndex: 'color_background',
    key: 'color_background',
    width: '10%',
    render: (text: any, record: any) => {
        return <div style={{ height: 25, width: 25, border: '1px solid grey', backgroundColor: record.color_background }}></div>
     }
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: (a: any, b: any) => {
      const firstTime = new Date(a.created_at).getTime();
      const secondTime = new Date(b.created_at).getTime();
      return secondTime - firstTime;
    },
    render: (text: any, record: any) => {
      const date = new Date(record.created_at);
      return date.toString();
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: any, record: any) => <Actions data={record} />,
    fixed: 'right',
    width: 200,
    align: 'right',
  },
];

  return (
    <div>
      <PageHeader
        title='Stories'
        ghost={false}
        extra={[
          <Search 
            placeholder='Search storie' 
            enterButton allowClear 
            onSearch={onSearch} 
            style={{ width: 350 }}
            onChange={onChange}
          />
        ]}
      />
      <div className='content_container'>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          loading={loading}
          scroll={{ x: 1500 }}
          rowKey={(record: any) => record.id}
        />
      </div>
      <ModalForm 
        type={modalForm.type}
        open={modalForm.open}
        onClose={closeModal}
        data={modalForm.data}
        onEdit={(payload: any) => editConfirm(payload)}
      />
    </div>
  );
}

export default Stories;
