import subcategoriesConstants from './constants';
import subcategoriesServices from './services';

const {
  SET_LOADING_SUBCATEGORIES,
  SET_SUBCATEGORIES,
  SET_EDITING_SUBCATEGORY,
  SET_EDITED_SUBCATEGORY,
  SET_CREATING_SUBCATEGORY,
  SET_CREATED_SUBCATEGORY,
  SET_DELETING_SUBCATEGORY,
  REMOVE_SUBCATEGORY,
} = subcategoriesConstants;

export const fetchAllSubcategories = (query?: any) => {
  return subcategoriesServices.fetchAll().catch(() => []);
};

const setLoadingSubcategories = (isLoadingSubcategories: boolean) => ({ type: SET_LOADING_SUBCATEGORIES, isLoadingSubcategories });
const setSubcategories = (subcategories: any) => ({ type: SET_SUBCATEGORIES, subcategories });

export const fetchSubcategories = () => {
  return (dispatch: any) => {
    dispatch(setLoadingSubcategories(true));
    subcategoriesServices.fetchAll().then((res) => {
      dispatch(setLoadingSubcategories(false));
      return dispatch(setSubcategories(res.data.subcategories));
    });
  };
};

const setCreatingSubcategory = (isCreatingSubcategory: boolean) => ({ type: SET_CREATING_SUBCATEGORY, isCreatingSubcategory });
const addSubcategory = (subcategory: any) => ({ type: SET_CREATED_SUBCATEGORY, subcategory });

export const createSubcategory = (data: any, callback: any) => {
  return (dispatch: any) => {
    dispatch(setCreatingSubcategory(true));
    subcategoriesServices.create(data).then((res: any) => {
      dispatch(setCreatingSubcategory(false));
      if (callback) callback();
      return dispatch(addSubcategory(res.data.category));
    });
  };
};

const setDeletingSubcategory = (isDeletingSubcategory: boolean) => ({ type: SET_DELETING_SUBCATEGORY, isDeletingSubcategory });
const removeSubcategory = (deletedSubcategoryId: string) => ({ type: REMOVE_SUBCATEGORY, deletedSubcategoryId });

export const deleteSubcategory = (id: string, callback: any) => {
  return (dispatch: any) => {
    dispatch(setDeletingSubcategory(true));
    subcategoriesServices.delete(id).then((res) => {
      dispatch(setDeletingSubcategory(false));
      if (callback) callback();
      return dispatch(removeSubcategory(id));
    });
  };
};

const setEditingSubcategory = (isEditingSubcategory: boolean) => ({
  type: SET_EDITING_SUBCATEGORY,
  isEditingSubcategory,
});
const setEditedSubcategory = (editedSubcategory: string) => ({
  type: SET_EDITED_SUBCATEGORY,
  editedSubcategory,
});
export const editSubcategory = (id: string, data: any, callback: any) => {
  return (dispatch: any) => {
    dispatch(setEditingSubcategory(true));
    subcategoriesServices.edit(id, data).then((res: any) => {
      dispatch(setEditingSubcategory(false));
      if (callback) callback();
      return dispatch(setEditedSubcategory(id));
    });
  };
};