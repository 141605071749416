import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageHeader, Table, Button, Space, Form, Popconfirm, Typography, InputNumber, Input, Select } from 'antd';
import recommendationsConstants from 'redux/recommendations/constants';
import usersConstants from 'redux/users/constants';
import {
  fetchAllRecommendations,
  setLoadingRecommendations,
  setRecommendations,
  acceptRecommendations,
  cancelRecommendations,
} from 'redux/recommendations/actions';
import store from 'redux/rootStore';
import transformRecommendations from 'utils/transformRecommendations';
import { fetchCategories } from 'redux/categories/actions';
import { fetchSubcategories } from 'redux/subcategories/actions';
import { fetchServices } from 'redux/services/actions';
import { fetchByNumber } from 'redux/users/actions';

const { PAGE_SIZE_OPTIONS, PAGE_SIZE } = recommendationsConstants;
const { ERRORS } = usersConstants;

const fetchRecommendationsHandler = () => {
  const { dispatch } = store;

  dispatch(setLoadingRecommendations(true));
  fetchAllRecommendations().then((data: any) => {
    if (data?.data?.recommendations) {
      const transformedRecommendations = transformRecommendations(data.data.recommendations);
      dispatch(setRecommendations(transformedRecommendations));
      dispatch(setLoadingRecommendations(false));
    }
  });
};


const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, options, required, mode, onChange, ...restProps }: any) => {
  let inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  switch (inputType){
    case 'number':
      inputNode = <InputNumber />
      break;
      case 'select':
        inputNode = (
          <Select mode={mode} allowClear onChange={onChange}>
            {options.map((option: any) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        )
      break;
      default:
        inputNode = <Input/>
      }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: required,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function Recommendations() {
  const { loading: loadingRecommendations, data: recommendationsData } = useSelector((state: any) => state.recommendations);
  const { loading: loadingCategories, data: categoriesData } = useSelector((state: any) => state.categories);
  const { loading: loadingSubcategories, data: subcategoriesData } = useSelector((state: any) => state.subcategories);
  const { loading: loadingServices, data: servicesData } = useSelector((state: any) => state.services);
  const [selectedRows, setSelectedRows] = useState([]);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [editingId, setEditingId] = useState('');
  const [form] = Form.useForm();
  const isEditing = (record: any) => record.id === editingId;
  const { dispatch } = store;
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);

  const columns: any = [
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      sorter: (a: any, b: any) => {
        if (a.owner.toLowerCase() < b.owner.toLowerCase()) {
          return -1;
        }
        if (a.owner.toLowerCase() > b.owner.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      width: 200,
    },
    {
      title: 'Receiver first name',
      dataIndex: 'reciever_first_name',
      key: 'reciever_first_name',
      sorter: (a: any, b: any) => {
        if (a.reciever_first_name.toLowerCase() < b.reciever_first_name.toLowerCase()) {
          return -1;
        }
        if (a.reciever_first_name.toLowerCase() > b.reciever_first_name.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      width: 200,
      editable: true
    },
    {
      title: 'Receiver last name',
      dataIndex: 'reciever_last_name',
      key: 'reciever_last_name',
      sorter: (a: any, b: any) => {
        if (a.reciever_last_name.toLowerCase() < b.reciever_last_name.toLowerCase()) {
          return -1;
        }
        if (a.reciever_last_name.toLowerCase() > b.reciever_last_name.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      width: 200,
      editable: true
    },
    {
      title: 'Receiver number',
      dataIndex: 'reciever_number',
      key: 'reciever_number',
      sorter: (a: any, b: any) => parseInt(a.reciever_number) - parseInt(b.reciever_number),
      width: 200,
      editable: true
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      sorter: (a: any, b: any) => {
        if (a.created_at < b.created_at) {
          return -1;
        }
        if (a.created_at > b.created_at) {
          return 1;
        }
        return 0;
      },
      width: 230,
      render: (text: any, record: any) => {
        const date = new Date(record.created_at);
        return date.toString();
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 150,
      editable: true,
      required: true,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a: any, b: any) => {
        if (a.category < b.category) {
          return -1;
        }
        if (a.category > b.category) {
          return 1;
        }
        return 0;
      },
      width: 150,
      editable: true,
      render: (_: any, record: any) => {
        const category = categoriesData.find((category: any) => category.id === record.category);
        if(category) return category.name;
      },
      inputType: 'select',
      options: categoriesData,
      required: true,
      onChange: (category_id: any) => {
        form.setFieldsValue({subcategory: null});
        filterSubcategories(category_id);
      }
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategory',
      key: 'subcategory',
      render: (_: any, record: any) => {
        const subcategory = subcategoriesData.find((subcategory: any) => subcategory.id === record.subcategory);
        if(subcategory) return subcategory.name;
      },
      width: 150,
      editable: true,
      inputType: 'select',
      options: filteredSubcategories,
      onChange: (subcategory_id: any) => {
        form.setFieldsValue({services: []});
        filterServices(subcategory_id);
      }
    },
    {
      title: 'Services',
      dataIndex: 'services',
      key: 'services',
      width: 150,
      editable: true,
      options: filteredServices,
      mode: 'multiple',
      inputType: 'select',
      render: (_: any, record: any) => {
        console.log(record)
        if(record.services){
          const services = servicesData.filter((service: any) => record.services.includes(service.id)).map((service: any) => service.name);
          if(services.length > 0) return services.join(', ');
        }
      },
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      width: 200,
      editable: true
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      width: 150,
      editable: true,
      inputType: 'number',
      required: true,
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_: String, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingId !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
      width: 200,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => {
        return (
          <Space>
            <Button type="primary" onClick={() => acceptRecommendationsHandler([record])}>
              Accept
            </Button>
            <Button type="primary" danger onClick={() => cancelRecommendationsHandler([record])}>
              Cancel
            </Button>
          </Space>
        );
      },
      fixed: 'right',
      width: 200,
    },
  ];

  const edit = (record: any) => {
    form.setFieldsValue({
      title: record.title,
      rating: record.rating,
      comment: record.comment,
      category: record.category,
      subcategory: record.subcategory,
      reciever_first_name: record.reciever_first_name,
      reciever_last_name: record.reciever_last_name,
      reciever_number: record.reciever_number
    });
    setEditingId(record.id);
    filterSubcategories(record.category);
    filterServices(record.subcategory);
  };

  const filterSubcategories = (category_id: String) => {
    setFilteredSubcategories(subcategoriesData.filter((subcategory: any) => subcategory.category_id === category_id));
  }
  const filterServices = (subcategory_id: String) => {
    setFilteredServices(servicesData.filter((service: any) => service.subcategory_id === subcategory_id).sort(function(a:any, b:any) {
      if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
     }));
  }

  const cancel = () => {
    setEditingId('');
  };

  const checkIfPhoneNumberValid = async (newRecommendation: any, oldRecommendation: any) => {
    const userData: any = await fetchByNumber(newRecommendation.reciever_number);
    if(!userData) return;
    const userPhoneNumber = userData?.data?.user?.phone_number;
    const isCurrentUser = userPhoneNumber === oldRecommendation.reciever_number;
    if(isCurrentUser) return;
    form.setFields([{ name: 'reciever_number', errors: [ERRORS.NUMBER_EXISTS] }]);
    throw new Error(ERRORS.NUMBER_EXISTS)
  }

  const save = async (id: any) => {
    try {
      const row = await form.validateFields();
      const newData = [...recommendationsData];
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const item = newData[index];
        await checkIfPhoneNumberValid(row, item);
        const newRecommendation = { ...item, ...row };
        newData.splice(index, 1, newRecommendation);
        dispatch(setRecommendations(newData));
        setEditingId('');
      } else {
        newData.push(row);
        dispatch(setRecommendations(newData));
        setEditingId('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options: col.options,
        required: col.required,
        mode: col.mode,
        onChange: col.onChange
      }),
    };
  });

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRows(selectedRows);
    },
  };

  useEffect(() => {
    fetchRecommendationsHandler();
    dispatch(fetchCategories());
    dispatch(fetchSubcategories());
    dispatch(fetchServices());
  }, []);

  useEffect(() => {
    if(subcategoriesData && subcategoriesData.length){
      setFilteredSubcategories(subcategoriesData);
    }
  }, [subcategoriesData]);

  useEffect(() => {
    if(servicesData && servicesData.length){
      setFilteredServices(servicesData);
    }
  }, [servicesData]);

  useEffect(() => {
    setActionsVisible(!!selectedRows.length);
  }, [selectedRows]);

  const acceptRecommendationsHandler = (recommendations: any) => {
    acceptRecommendations(recommendations).then(fetchRecommendationsHandler);
  };

  const cancelRecommendationsHandler = (recommendations: any) => {
    cancelRecommendations(recommendations).then(fetchRecommendationsHandler);
  };

  return (
    <div>
      <PageHeader
        title="Recommendations"
        ghost={false}
        extra={[
          <>
            {actionsVisible ? (
              <Space>
                <Button type="primary" onClick={() => acceptRecommendationsHandler(selectedRows)}>
                  Accept selected
                </Button>
                <Button type="primary" danger onClick={() => cancelRecommendationsHandler(selectedRows)}>
                  Cancel selected
                </Button>
              </Space>
            ) : null}
          </>,
        ]}
      />
      <div className="content_container">
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={mergedColumns}
            dataSource={recommendationsData}
            pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
            loading={loadingCategories || loadingSubcategories || loadingRecommendations || loadingServices}
            rowSelection={rowSelection}
            scroll={{ x: 1500 }}
          />
        </Form>
      </div>
    </div>
  );
}

export default Recommendations;
