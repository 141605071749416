const subcategoriesConstants = {
  SET_LOADING_SUBCATEGORIES: 'SET_LOADING_SUBCATEGORIES',
  SET_SUBCATEGORIES: 'SET_SUBCATEGORIES',
  SET_CREATING_SUBCATEGORY: 'SET_CREATING_SUBCATEGORY',
  SET_CREATED_SUBCATEGORY: 'SET_CREATED_SUBCATEGORY',
  SET_DELETING_SUBCATEGORY: 'SET_DELETING_SUBCATEGORY',
  REMOVE_SUBCATEGORY: 'REMOVE_SUBCATEGORY',
  SET_EDITING_SUBCATEGORY: 'SET_EDITING_SUBCATEGORY',
  SET_EDITED_SUBCATEGORY: 'SET_EDITED_SUBCATEGORY',
  PAGE_SIZE_OPTIONS: ['10', '25', '50', '100'],
  PAGE_SIZE: 50,
};

export default subcategoriesConstants;
