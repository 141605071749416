const adminsConstants = {
  SET_LOADING_ADMINS: 'SET_LOADING_ADMINS',
  SET_ADMINS: 'SET_ADMINS',
  SET_CREATING_ADMIN: 'SET_CREATING_ADMIN',
  SET_CREATED_ADMIN: 'SET_CREATED_ADMIN',
  SET_DELETING_ADMIN: 'SET_DELETING_ADMIN',
  REMOVE_ADMIN: 'REMOVE_ADMIN',
  PAGE_SIZE_OPTIONS: ['10', '25', '50', '100'],
  PAGE_SIZE: 50
};

export default adminsConstants;
