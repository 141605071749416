import reportsService from './services';
import reportsConstants from './constants';

const { SET_LOADING_REPORTS, SET_REPORTS_STORIES, SET_STATS_STORIES } = reportsConstants;

export const setLoadingReports = (isLoadingReports: boolean) => ({ type: SET_LOADING_REPORTS, isLoadingReports });
export const setReports = (reports: any) => ({ type: SET_REPORTS_STORIES, reports });
export const setReportsStats = (reports: any) => ({ type: SET_STATS_STORIES, reports }); 

export const fetchStories = (query?: any) => {
  return reportsService.fetch().catch(() => []);
};

export const deleteStorie = (storie_id: any) => {
  return reportsService.deleteStorie(storie_id);
}

export const updateStorie = (data: object) => {
  return reportsService.updateStorie(data);
}

export const fetchStats = (query?: any) => {
  return reportsService.fetchStats();
}

export const blockReportedUser = (reciever_id: String, type: String) => {
  return reportsService.blockReportedUser(reciever_id, type).catch((err: any) => err);
};

export const unblockReportedUser = (reciever_id: String, type: String, report_id: String) => {
  return reportsService.unblockReportedUser(reciever_id, type, report_id).catch((err: any) => err);
};
