const categoriesConstants = {
  SET_LOADING_CATEGORIES: 'SET_LOADING_CATEGORIES',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_CREATING_CATEGORY: 'SET_CREATING_CATEGORY',
  SET_CREATED_CATEGORY: 'SET_CREATED_CATEGORY',
  SET_DELETING_CATEGORIES: 'SET_DELETING_CATEGORIES',
  REMOVE_CATEGORY: 'REMOVE_CATEGORY',
  SET_EDITING_CATEGORIES: 'SET_EDITING_CATEGORIES',
  SET_EDITED_CATEGORIES: 'SET_EDITED_CATEGORIES',
  PAGE_SIZE_OPTIONS: ['10', '25', '50', '100'],
  PAGE_SIZE: 50,
};

export default categoriesConstants;
