import React, { useEffect, useState } from 'react';
import { PageHeader, Table, Input, Button, Select } from 'antd';
import { DatePicker, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import domainStatsConstants from 'redux/domainstats/constants';
import { fetchDomainStats, setLoadingDomainStats } from 'redux/domainstats/actions';

const { RangePicker } = DatePicker;
const { PAGE_SIZE_OPTIONS, PAGE_SIZE } = domainStatsConstants;
const { Search } = Input;
const columns = [
  {
    title: 'Fecha',
    dataIndex: 'created_at',
    key: 'created_at',
    ellipsis: true,
    sorter: (a: any, b: any) => {
      if (a.created_at < b.created_at) {
        return -1;
      }
      if (a.created_at > b.created_at) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
    ellipsis: true,
    sorter: (a: any, b: any) => {
      if (a.domain.toLowerCase() < b.domain.toLowerCase()) {
        return -1;
      }
      if (a.domain.toLowerCase() > b.domain.toLowerCase()) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Origin IP',
    dataIndex: 'originIP',
    key: 'originIP',
    ellipsis: true,
    sorter: (a: any, b: any) => {
      if (a.originIP.toLowerCase() < b.originIP.toLowerCase()) {
        return -1;
      }
      if (a.originIP.toLowerCase() > b.originIP.toLowerCase()) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Event',
    dataIndex: 'event',
    key: 'event',
    sorter: (a: any, b: any) => {
      if (a.event.toLowerCase() < b.event.toLowerCase()) {
        return -1;
      }
      if (a.event.toLowerCase() > b.event.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    render: (text: any, record: any) => record.event,
  },
  

];

function ReportsDomains() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { loading } = useSelector((state: any) => state.domainstats);
  const [filteredDomainStats, setFilteredDomainStats] = useState([]);
  const [filteredDomainNames, setFilteredDomainNames] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [stats, setStats] = useState({share:0, pageView:0, recommend:0, message:0, calls:0, users:0})
  const [statsVisible, setStatsVisible] = useState(false);

  const [load, setLoad] = useState(true);

  const fetchAllDomainStats = () => {
    setLoad(true);
    dispatch(setLoadingDomainStats(true));
    fetchDomainStats().then((data: any) => {
      if (data?.data?.stats) {
        setFilteredDomainStats(data.data.stats);
        setData(data.data.stats);
        let uniqueDomainArray:any = [...new Map(data.data.stats.map((item:any) => [item["domain"], item])).values()];
        setFilteredDomainNames(uniqueDomainArray.map((item:any) => ({ value:item["domain"], label:item["domain"]}) ));
        dispatch(setLoadingDomainStats(false));
        setLoad(false);
      }
    });
  };

  useEffect(() => {
    fetchAllDomainStats();
  }, [dispatch]);

  const onChangeDate = (date: any, dateString: any) => {
    setLoad(true);
    setFromDate(date[0]);
    setToDate(date[1]);
    const firstDate = new Date(new Date(dateString[0]).setHours(0, 0, 0, 0)).getTime();
    const lastDate = new Date(new Date(dateString[1]).setHours(23, 59, 59, 999)).getTime();

    const filteredData = data.filter(
      (row: any) =>{
        console.log(new Date(row.created_at).getTime());
        if (new Date(row.created_at).getTime() > firstDate && new Date(row.created_at).getTime() < lastDate) {
          return true;
        } 
      }
    );
    
    let auxFilterData = data;
    if (selectedDomain != '') {
      const filteredDataDomains = filteredData.filter(
        (row: any) =>{
          if (row.domain == selectedDomain) {
            return true;
          } 
        }
      );
      setFilteredDomainStats(filteredDataDomains);
      auxFilterData = filteredDataDomains;
    } else{
      setFilteredDomainStats(filteredData);
      auxFilterData = filteredData;
    }
    const filterObj = auxFilterData.filter( (item) => item['event'] === "pageView" );
    const filterObjDuplicates = filterObj.filter((v,i,a)=>a.findIndex(v2 => (v2['originIP'] === v['originIP'])) === i);
    const share = auxFilterData.filter(({event}) => event === 'share').length
    const pageView = auxFilterData.filter(({event}) => event === 'pageView').length
    const recommend = auxFilterData.filter(({event}) => event === 'recommend').length
    const message = auxFilterData.filter(({event}) => event === 'message').length
    const calls = auxFilterData.filter(({event}) => event === 'call').length
    const users = filterObjDuplicates.length;
    setStats({share:share, pageView:pageView, recommend:recommend, message:message, calls:calls, users:users})
    setLoad(false);
  };


  const onChange = (e: any) => {
    const value = e.target.value;
    if (!value.length) fetchAllDomainStats();
  };

  const onResetFilters = (e: any) => {
    setSelectedDomain('');
    setFromDate(null);
    setToDate(null);
    setStats({share:0, pageView:0, recommend:0, message:0, calls:0, users:0});
    setStatsVisible(false);
    fetchAllDomainStats();
  };

  const onChangeDomain = (value: any) => {
    console.log(`selected ${value}`);
    setSelectedDomain(value)
    const filteredData = data.filter(
      (row: any) =>{
        if (row.domain == value) {
          return true;
        } 
      }
    );
    let auxFilterData = data;
    if (fromDate != null && toDate != null) {
      const firstDate = new Date(new Date(fromDate!).setHours(0, 0, 0, 0)).getTime();
      const lastDate = new Date(new Date(toDate!).setHours(23, 59, 59, 999)).getTime();

      const filteredDataTime = filteredData.filter(
        (row: any) =>{
          console.log(new Date(row.created_at).getTime());
          if (new Date(row.created_at).getTime() > firstDate && new Date(row.created_at).getTime() < lastDate) {
            return true;
          } 
        }
      )
      setFilteredDomainStats(filteredDataTime);
      auxFilterData = filteredDataTime;
    } else{
      setFilteredDomainStats(filteredData);
      auxFilterData = filteredData;
    }
    const filterObj = auxFilterData.filter( (item) => item['event'] === "pageView" );
    const filterObjDuplicates = filterObj.filter((v,i,a)=>a.findIndex(v2 => (v2['originIP'] === v['originIP'])) === i);
    const share = auxFilterData.filter(({event}) => event === 'share').length
    const pageView = auxFilterData.filter(({event}) => event === 'pageView').length
    const recommend = auxFilterData.filter(({event}) => event === 'recommend').length
    const message = auxFilterData.filter(({event}) => event === 'message').length
    const calls = auxFilterData.filter(({event}) => event === 'call').length
    const users = filterObjDuplicates.length;

    setStats({share:share, pageView:pageView, recommend:recommend, message:message, calls:calls, users:users})
    setStatsVisible(true);
  };
  const onSearchDomain = (value: any) => {
    console.log('search:', value);
  };

  return (
    <div>
      <PageHeader title='Domains reports' ghost={false} 
      extra={[
        <>
          <RangePicker onChange={onChangeDate} value={[fromDate, toDate]}/>
          <Select
            showSearch
            value={selectedDomain}
            placeholder="Select a domain"
            optionFilterProp="children"
            onChange={onChangeDomain}
            onSearch={onSearchDomain}
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            style={{
              width: 200,
            }}
            options={filteredDomainNames}
          />
          <Button type="primary" onClick={onResetFilters}>
            Reset
          </Button>
        </>
      ]}
      />
      {statsVisible ? 
      <div className='content_container'>
        <Space>
          share: {stats.share } - pageView: {stats.pageView} - recommend: {stats.recommend} - message: {stats.message} - calls: {stats.calls} - users: {stats.users}
        </Space>
      </div> : <></>}
      
      <div className='content_container'>
        <Table
          columns={columns}
          dataSource={filteredDomainStats}
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          loading={loading || load}
        />
      </div>
    </div>
  );
}

export default ReportsDomains;