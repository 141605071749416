import React, { useEffect, useState } from 'react';
import { PageHeader, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import deactivatedUsersConstants from 'redux/deactivated/constants';
import { fetchDeactivatedUsers, setLoadingDeactivatedUsers } from 'redux/deactivated/actions';

const { PAGE_SIZE_OPTIONS, PAGE_SIZE } = deactivatedUsersConstants;

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
    sorter: (a: any, b: any) => {
      if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
        return -1;
      }
      if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
        return 1;
      }
      if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) {
        return -1;
      }
      if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    render: (text: any, record: any) => {
      const firstName = record.first_name;
      const lastName = record.last_name;
      return `${firstName} ${lastName}`;
    },
  },
  {
    title: 'User Id',
    dataIndex: 'id',
    key: 'id',
    ellipsis: false,
    onFilter: (value: any, record: any) => record.user_id.indexOf(value) === 0,
    sorter: (a: any, b: any) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Phone number',
    dataIndex: 'phone_number',
    key: 'phone_number',
    ellipsis: true,
    onFilter: (value: any, record: any) => record.phone_number.indexOf(value) === 0,
    sorter: (a: any, b: any) => parseInt(a.phone_number) - parseInt(b.phone_number),
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    sorter: (a: any, b: any) => {
      if (a.business.deactivation_comment.toLowerCase() < b.business.deactivation_comment.toLowerCase()) {
        return -1;
      }
      if (a.business.deactivation_comment.toLowerCase() > b.business.deactivation_comment.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    render: (text: any, record: any) => record.business.deactivation_comment,
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: (a: any, b: any) => {
      if (a.business.deleted_at < b.business.deleted_at) {
        return -1;
      }
      if (a.business.deleted_at > b.business.deleted_at) {
        return 1;
      }
      return 0;
    },
    render: (text: any, record: any) => {
      const date = new Date(record.business.deleted_at);
      return date.toString();
    },
  },
];

function DeactivatedUsers() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { loading } = useSelector((state: any) => state.deactivatedUsers);

  useEffect(() => {
    dispatch(setLoadingDeactivatedUsers(true));
    fetchDeactivatedUsers().then((data: any) => {
      if (data?.data?.deactivatedUsers) {
        setData(data.data.deactivatedUsers);
        dispatch(setLoadingDeactivatedUsers(false));
      }
    });
  }, [dispatch]);

  return (
    <div>
      <PageHeader title='Deactivated users' ghost={false} />
      <div className='content_container'>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default DeactivatedUsers;
