import { combineReducers } from 'redux';

import auth from "./auth/reducer";
import admins from "./admins/reducer";
import categories from "./categories/reducer";
import subcategories from "./subcategories/reducer";
import services from "./services/reducer";
import feedbacks from "./feedbacks/reducer";
import reports from "./reports/reducer";
import deactivatedUsers from './deactivated/reducer';
import users from "./users/reducer";
import recommendations from "./recommendations/reducer";
import stats from "./stats/reducer";
import storiesReports from './reports-stories/reducer';
import stories from './stories/reducer';
import domainstats from "./domainstats/reducer";


// Use ES6 object literal shorthand syntax to define the object shape
const rootReducer = combineReducers({
  auth,
  admins,
  categories,
  subcategories,
  services,
  feedbacks,
  reports,
  deactivatedUsers,
  users,
  recommendations,
  stats,
  storiesReports,
  stories,
  domainstats,
});

export default rootReducer;