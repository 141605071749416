import statsConstants from './constants';

const { SET_LOADING_STATS } = statsConstants;

const initialState = {
  loading: false,
  data: [],
};

const statsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING_STATS: {
      return {
        ...state,
        loading: action.isLoadingStats,
      };
    }
    default: {
      return state;
    }
  }
};

export default statsReducer;
