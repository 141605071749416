import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Form, Spin } from 'antd';
import api from 'helpers/api';

const orderdateImages = (values: any) => {
  return values.sort((a: any, b: any) => {
      let ax = new Date(a.created_at);
      let bx = new Date(b.created_at);
      return bx.getTime() - ax.getTime()
  })
}

const GalleryModal = ({ current, id }: any) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [photos, setPhotos] = useState(orderdateImages(current));
  const [loading, setLoading] = useState({
      show: false,
      text: ''
  });

  const path = 'https://recomiend-storage.nyc3.digitaloceanspaces.com/gallery_users';

  useEffect(() => {
    setPhotos(current);
  },[current])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onImageChange = async (e: any) => {
    setLoading({
        show: true,
        text: 'Uploading image, please wait.'
    });
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    const response = await api.put(`/users/gallery/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    setPhotos(response.data.photos);
    setLoading({ show: false, text: '' });
  }

  const deleteImage = async (id: string, user: string) => {
    setLoading({
        show: true,
        text: 'Removing image, please wait.'
    });
    const response = await api.delete(`/users/gallery/${id}/${user}`);
    setPhotos(response.data.photos);
    setLoading({ show: false, text: '' });
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
         Gallery Management 
      </Button>
      <Modal title="User gallery Management " visible={isModalVisible} onCancel={handleCancel} width={1000} okButtonProps={{
          style: {
            display: "none",
          },
        }}>
        {photos.length >= 20 ? 
            <div style={{ color: 'red' }}>
                This user has max images load (20), for upload other image should remove one.
            </div>
        : null}

        {!loading.show ? 
            <div style={{ marginBottom: 30 }}>
                <div>Upload one image</div>
                <input type="file" onChange={onImageChange} disabled={photos.length >= 6 ? true : false} />
            </div>
                :
            <div style={{ marginBottom: 30, flexDirection: 'row', display: 'flex' }}>
                <div style={{ marginRight: 10 }}>{loading.text}</div>
                <Spin/>
            </div>
        }

        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {!photos.length ? 
                <div style={{ height: 100, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    This gallery is empty
                </div>
            :
            photos.map((item: any, index: number) => {
                return <div key={index} style={{ width: 200, height: 225, marginRight: 5, border:'1px solid #ccc', padding: 5, marginBottom: 5 }}>
                    <img src={`${path}/${item.image}`} style={{ width: '100%' }}/>
                    <div onClick={() =>deleteImage(item.id, id)} style={{ width: '100%', height: 25, alignItems: 'center', justifyContent: 'center', backgroundColor: '#4646A4', display: 'flex', color:'white', cursor: 'pointer' }}>
                        Delete
                    </div>
                </div>
            })}
        </div>
      </Modal>
    </>
  );
};

export default GalleryModal;