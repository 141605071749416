import statsService from './services';
import statsConstants from './constants';

const { SET_LOADING_STATS } = statsConstants;

export const setLoadingStats = (isLoadingStats: boolean) => ({
  type: SET_LOADING_STATS,
  isLoadingStats,
});

export const fetchStats = (query?: any) => {
  return statsService.fetch().catch(() => []);
};
