import React, { useEffect, useState } from 'react';
import { PageHeader, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import feedbacksConstants from 'redux/feedbacks/constants';
import { fetchFeedbacks, setLoadingFeedbacks } from 'redux/feedbacks/actions';

const { PAGE_SIZE_OPTIONS, PAGE_SIZE } = feedbacksConstants;

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
    sorter: (a: any, b: any) => {
      if (a.user.first_name.toLowerCase() < b.user.first_name.toLowerCase()) {
        return -1;
      }
      if (a.user.first_name.toLowerCase() > b.user.first_name.toLowerCase()) {
        return 1;
      }
      if (a.user.last_name.toLowerCase() < b.user.last_name.toLowerCase()) {
        return -1;
      }
      if (a.user.last_name.toLowerCase() > b.user.last_name.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    render: (text: any, record: any) => {
      const firstName = record.user.first_name;
      const lastName = record.user.last_name;
      return `${firstName} ${lastName}`;
    },
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    ellipsis: true,
    sorter: (a: any, b: any) => {
      if (a.created_at < b.created_at) {
        return -1;
      }
      if (a.created_at > b.created_at) {
        return 1;
      }
      return 0;
    },
    render: (text: any, record: any) => {
      const date = new Date(record.created_at);
      return date.toString();
    },
  },
  {
    title: 'Phone number',
    dataIndex: 'phone_number',
    key: 'phone_number',
    ellipsis: true,
    onFilter: (value: any, record: any) => record.phone_number.indexOf(value) === 0,
    sorter: (a: any, b: any) => parseInt(a.user.phone_number) - parseInt(b.user.phone_number),
    render: (text: any, record: any) => record.user.phone_number,
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason',
    sorter: (a: any, b: any) => {
      if (a.reason < b.reason) {
        return -1;
      }
      if (a.reason > b.reason) {
        return 1;
      }
      return 0;
    },
    filters: [
      {
        text: 'Errores',
        value: 'Errores',
      },
      {
        text: 'Mejoras',
        value: 'Mejoras',
      },
      {
        text: 'Quejas',
        value: 'Quejas',
      },
      {
        text: 'Otros',
        value: 'Otros',
      },
    ],
    filterMultiple: false,
    onFilter: (value: any, record: any) => record.reason.indexOf(value) === 0,
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    sorter: (a: any, b: any) => {
      if (a.comment.toLowerCase() < b.comment.toLowerCase()) {
        return -1;
      }
      if (a.comment.toLowerCase() > b.comment.toLowerCase()) {
        return 1;
      }
      return 0;
    },
  },
];

function Feedbacks() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { loading } = useSelector((state: any) => state.feedbacks);

  useEffect(() => {
    dispatch(setLoadingFeedbacks(true));
    fetchFeedbacks().then((data: any) => {
      if (data?.data?.feedbacks) {
        setData(data.data.feedbacks);
        dispatch(setLoadingFeedbacks(false));
      }
    });
  }, [dispatch]);

  return (
    <div>
      <PageHeader title='Feedbacks' ghost={false} />
      <div className='content_container'>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default Feedbacks;
