import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  message,
  Select,
  Row,
  Col,
  Table,
  Popconfirm,
  Typography,
  InputNumber,
  Switch,
  Space,
  Upload 
} from "antd";
import styles from "../styles.module.scss";
import { fetchUserRecommendations } from "redux/users/actions";
import transformRecommendations from "utils/transformRecommendations";
import { editRecommendation } from "redux/recommendations/actions";
import { getImagenUrl } from "../../utils/getImageUrl";
import { getImageCustomHeaderUrl } from "../../utils/getImageCustomHeaderUrl";
import GalleryModal from "./GalleryModal/GalleryModal";
import { createService, fetchServices } from '../../redux/services/actions';
import { fetchSubcategories } from '../../redux/subcategories/actions';
import { useDispatch, useSelector } from 'react-redux';
import { SketchPicker } from 'react-color';
import { customDomainServices } from '../../services/users/customDomain';


const PATH_WEB_PUBLIC = "https://recomiend.app/pro";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  options,
  required,
  mode,
  onChange,
  ...restProps
}: any) => {
  let inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  switch (inputType) {
    case "number":
      inputNode = <InputNumber />;
      break;
    default:
      inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: required,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function UserModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  successMessage?: string;
  categories?: any[];
  subcategories?: any[];
  services?: any[];
  itemId?: string;
  data?: any;
}) {
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [imageFileCustomHeader, setImageFileCustomHeader] = useState("");
  const [imageURLCustomHeader, setImageURLCustomHeader] = useState("");
  const [visible, setVisible] = useState(false);
  const [categoryId, setCategoryId] = useState(false);
  const [subcategoryId, setSubcategoryId] = useState(false);
  const [latitude, setLatitude] = useState(false);
  const [longitude, setLongitude] = useState(false);
  const [filteredSubcategories, setFilteredSubcategories]: any = useState([]);
  const [recommendations, setRecommendations]: any = useState(null);
  const [recommendationsLoading, setRecommendationsLoading]: any =
    useState(false);
  const [form] = Form.useForm();
  const [recommendationsForm] = Form.useForm();
  const {
    modalTitle,
    openButtonText,
    onConfirm,
    successMessage,
    itemId,
    data,
    categories,
    subcategories,
    services,
  } = props;

  const [filteredServices, setFilteredServices]: any = useState([]);
  const [currentSubcategories, setCurrentSubcategories]: any = useState(subcategories);
  const isEditing = (record: any) => record.id === editingId;
  const [editingId, setEditingId] = useState("");
  const [showSocial, setShowSocial] = useState(false);
  const [createItemService, setCreateService] = useState(false);
  const [colorPicker, setColorPicker] = useState('#454B99');
  const { loading: loadingServices, data: currentServices } = useSelector((state: any) => state.services);
  const [filters] = useState({
    nested: true,
  });
  const [disabledSwitchPersonalDomain, setDisabledSwitchPersonalDomain] = useState(true);
  const togglePersonalDomain = () => {
    setDisabledSwitchPersonalDomain(!disabledSwitchPersonalDomain);
  };
  const inputRef = useRef<any>(null);
  useEffect(() => {
    setShowSocial(data.premiun);
    if (data != null && data.personal_domain != null && data.personal_domain.length != 0){
      setDisabledSwitchPersonalDomain(false)
    }
    if (data != null && data.custom_color_web != null && data.custom_color_web.length != 0){
      console.log(data.custom_color_web);
      setColorPicker(data.custom_color_web);
    }
  }, [data]);
    // On first load, fetch data
  useEffect(() => {
    const currentServicesBySubcategoryId = currentServices?.filter(
      (item: any) => item.subcategory_id === subcategoryId
    );
    setFilteredServices(currentServicesBySubcategoryId.sort());
    setCreateService(false);
    if (inputRef.current != null) inputRef.current.value = '';
  }, [currentServices]);

  const onChangeInputPersonalDomain = (e:any) => {
    if (e.target.value.length > 0 && e.target.value.includes('https') && e.target.value.charAt(e.target.value.length - 1) != '/'){
      setDisabledSwitchPersonalDomain(false);
    } else {
      setDisabledSwitchPersonalDomain(true);
    }
  };
  const createItem = (data: { name: string; subcategory_id: string; category_id: string }) => {
    dispatch(
      createService(data, () => {
        dispatch(fetchServices(filters));
      })
    );
  };
  const recommendationsColumns: any = [
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      sorter: (a: any, b: any) => {
        if (a.owner < b.owner) {
          return -1;
        }
        if (a.owner > b.owner) {
          return 1;
        }
        return 0;
      },
      width: 200,
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: 200,
      editable: true,
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      width: 150,
      editable: true,
      inputType: "number",
      required: false,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_: String, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingId !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
      width: 200,
    },
  ];

  const openModal = () => {
    const initialImageUrl = data && getImagenUrl(data.image);
    setImageURL(initialImageUrl);
    const initialImageUrlCusdomHerader = data && getImageCustomHeaderUrl(data.imagecustomheader);
    setImageURLCustomHeader(initialImageUrlCusdomHerader);
    setCategoryId(data?.category_id);
    setLatitude(data?.latitude);
    setLongitude(data?.longitude);
    setVisible(true);
  };
  const errorCreateCustomDomain = () => {
    message.error("Could not create custom domain");
  };
  const onCancel = () => setVisible(false);
  const onSuccess = () => {
    setVisible(false);
    form.resetFields();
    message.success(successMessage || "Exit");
  };
  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        let customDomains:any;
        if (values.use_personal_domain) {
          customDomains = customDomainServices.createDomain(values.personal_domain, values.web_public_id.replace('https://recomiend.app/pro/', '')) 
        }
        console.log(values);
        // Generate form data
        const formData = new FormData();
        // Get image into form data
        if (imageFile) {
          console.log(imageFile);
          formData.append("image", imageFile);
        }
        
        if (imageFileCustomHeader) {
          formData.append("imagecustomheader", imageFileCustomHeader);
        }

        if (colorPicker != null) {
          values.custom_color_web = colorPicker;
        }

        if (!values.hasOwnProperty("default")) {
          values.default = "false";
        } else {
          values.default = values.default.toString();
        }

        if (!values.hasOwnProperty("premiun")) {
          values.premiun = "false";
        } else {
          values.premiun = values.premiun.toString();
        }

        if (!values.hasOwnProperty("use_personal_domain")) {
          values.use_personal_domain = false;
        } else {
          values.use_personal_domain = values.use_personal_domain.toString();
        }

       

        for (const key in values) {
          console.log(key + ': '+ values[key])
          if (values[key] !== undefined && values[key] !== null) {
            formData.append(key, values[key]);
          } 
        }

        delete values.web_public_id;

        if (!values.services_ids) formData.append("services_ids", "");
        let object = {};
        
        const json = JSON.stringify(Object.fromEntries(formData));
        console.log(json)
        onConfirm(itemId, formData, onSuccess);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const onImageChange = (e: any) => {
    setImageFile(e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]));
  };
  const onImageChangeCustomHeader = (e: any) => {
    console.log(e.target.files[0]);
    console.log(URL.createObjectURL(e.target.files[0]))
    setImageFileCustomHeader(e.target.files[0]);
    setImageURLCustomHeader(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    if (categoryId) {
      const subCategorieRefCategory = subcategories?.filter(
        (item) => item.category_id === categoryId
      );
      setFilteredSubcategories(subCategorieRefCategory);
    }

    if (subcategoryId) {
      const servicesSubcategory = filteredSubcategories?.filter(
        (item: any) => item.id === subcategoryId
      )[0];
      if (servicesSubcategory) {
        setFilteredServices(servicesSubcategory.services.sort(sortFilteredServices));

      }
    }
  }, [categoryId, subcategoryId]);
  const sortFilteredServices = (x:any, y: any) =>{
    return x.name.localeCompare(y.name, 'es', {ignorePunctuation: true});
  }
  const handleChangeColorPicker = (color:any) =>{
    setColorPicker(color.hex);
  } 
  const handleCategoryChange = (value: any) => {
    setCategoryId(value);
    form.setFieldsValue({
      subcategory_id: null,
      subcategoryId: null,
      services_ids: [],
      category_id: value,
    });
  };

  const handleSubcategoryChange = (value: any) => {
    setSubcategoryId(value);
    form.setFieldsValue({ subcategory_id: value, services_ids: [] });
  };

  const handleServicesChange = (value: any) => {
    form.setFieldsValue({ services_ids: value });
  };
  const handleCreateService = () => {
    if (inputRef.current != null) {
      const newServicedata = { name: inputRef.current!.value, subcategory_id: data?.subcategory_id, category_id:data?.category_id }
      const itemService = createItem(newServicedata);
    }
  }


  useEffect(() => {
    if (visible) {
      const servicesUser = data?.services.map((item: any) => item.id);
      setCategoryId(data?.category_id);
      setSubcategoryId(data?.subcategory_id);
      console.log(data);
      form.setFieldsValue({
        category_id: data?.category_id,
        subcategory_id: data?.subcategory_id,
        services_ids: servicesUser,
        ...data,
        web_public_id: PATH_WEB_PUBLIC + "/" + data.web_public_id,
      });
    }
  }, [visible]);



  const handleShowRecommendations = () => {
    setRecommendationsLoading(true);
    fetchUserRecommendations(data?.id).then((data) => {
      const recommendationsRecieved = data?.data?.recommendations;
      if (recommendationsRecieved) {
        const transformedRecommendations = transformRecommendations(
          recommendationsRecieved
        );
        setRecommendations(transformedRecommendations);
        setRecommendationsLoading(false);
      }
    });
  };

  const handleShowLocation = () => {
    window.open(
      "https://maps.google.com/?q=" + data?.latitude + "," + data?.longitude,
      "_blank"
    );
  };

  const mergedColumns = recommendationsColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options: col.options,
        required: col.required,
        mode: col.mode,
        onChange: col.onChange,
      }),
    };
  });

  const edit = (record: any) => {
    recommendationsForm.setFieldsValue({
      rating: record.rating,
      comment: record.comment,
    });
    setEditingId(record.id);
  };

  const cancel = () => {
    setEditingId("");
  };

  const save = async (id: any) => {
    try {
      const row = await recommendationsForm.validateFields();
      const newData = [...recommendations];
      const index = newData.findIndex((item) => id === item.id);

      const item = newData[index];
      const newRecommendation = { ...item, ...row };
      newData.splice(index, 1, newRecommendation);
      editRecommendation(newRecommendation);
      setRecommendations(newData);
      setEditingId("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const openWebPublic = () => {
    window.open(PATH_WEB_PUBLIC + "/" + data.web_public_id);
  };

  return (
    <>
      <Button type="primary" onClick={openModal}>
        {openButtonText}
      </Button>
      <Modal
        title={modalTitle}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        width={1000}
      >
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First name"
                name="first_name"
                rules={[
                  { required: false, message: "Please enter first name" },
                ]}
              >
                <Input placeholder="First name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last name"
                name="last_name"
                rules={[{ required: false, message: "Please enter last name" }]}
              >
                <Input placeholder="Last name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: false, message: "Please enter email" },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Allowed advertising"
                name="allowed_advertising"
                valuePropName="checked"
              >
                <Switch
                  defaultChecked={data.allowed_advertising}
                  checked={data.allowed_advertising}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              {Object.entries(data.business).length ? (
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    { required: false, message: "Please enter the title" },
                  ]}
                >
                  <Input placeholder="Title" />
                </Form.Item>
              ) : null}

              <Form.Item
                label="Phone number"
                name="phone_number"
                rules={[
                  { required: false, message: "Please enter the phone number" },
                ]}
              >
                <Input placeholder="Phone number" />
              </Form.Item>
              {Object.entries(data.business).length ? (
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="User premiun"
                      name="premiun"
                      valuePropName="checked"
                    >
                      <Switch
                        onChange={(val) => setShowSocial(val)}
                        defaultChecked={data.premiun}
                        checked={data.premiun}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="User default"
                      name="default"
                      valuePropName="checked"
                    >
                      <Switch
                        defaultChecked={data.default}
                        checked={data.default}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
            </Col>
            <Col span={12}>
              <Form.Item label="Image" name="image">
                <div className={styles.imagePreviewContainer}>
                  {imageURL ? (
                    <img
                      className={styles.imagePreview}
                      src={imageURL}
                      alt="avatar"
                    />
                  ) : null}
                </div>
                <Input type="file" onChange={onImageChange} />
              </Form.Item>
            </Col>
          </Row>
          {showSocial ? (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Whatsapp"
                  name="whatsapp"
                  rules={[
                    { required: false, message: "Please enter the title" },
                  ]}
                >
                  <Input placeholder="Whatsapp" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="YouTube"
                  name="youtube"
                  rules={[
                    { required: false, message: "Please enter the title" },
                  ]}
                >
                  <Input placeholder="Youtube" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Facebook"
                  name="facebook"
                  rules={[
                    { required: false, message: "Please enter the title" },
                  ]}
                >
                  <Input placeholder="Facebook" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Twitter"
                  name="twitter"
                  rules={[
                    { required: false, message: "Please enter the title" },
                  ]}
                >
                  <Input placeholder="Twitter" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Instagram"
                  name="instagram"
                  rules={[
                    { required: false, message: "Please enter the title" },
                  ]}
                >
                  <Input placeholder="Instagram" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="LinkedIn"
                  name="linkedin"
                  rules={[
                    { required: false, message: "Please enter the title" },
                  ]}
                >
                  <Input placeholder="LinkedIn" />
                </Form.Item>
              </Col>
            </Row>
          ) : null}
          {showSocial ? (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="WebPage"
                  name="webpage"
                  rules={[
                    { required: false, message: "Please enter the title" },
                  ]}
                >
                  <Input placeholder="webpage" />
                </Form.Item>
              </Col>
              <Col style={{ display: "flex", alignItems: "center" }}>
                <GalleryModal
                  current={data.professional_gallery}
                  id={data.id}
                />
              </Col>
            </Row>
          ) : null}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Web Public"
                name="web_public_id"
                rules={[{ required: false }]}
              >
                <Input placeholder="webpage" disabled />
              </Form.Item>
            </Col>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <Button type="primary" onClick={openWebPublic}>
                Open Web Public
              </Button>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Personal Domain: example 'https://mypersonaldomain.com'"
                name="personal_domain"

                rules={[
                  {
                    required: false,
                    message: 'Please enter the personal domain!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      console.log(value==='');
                      if ( value === null || value === '' || (value.includes('https') && value.charAt(value.length - 1) != '/')) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The personal domain must have https and not end with "/"!'));
                    },
                  }),
                ]}
              >
                <Input onChange={onChangeInputPersonalDomain} placeholder="domain personal" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Active personal domain"
                name="use_personal_domain"
                valuePropName="checked"
              >
                <Switch disabled={disabledSwitchPersonalDomain}
                  defaultChecked={data.use_personal_domain}
                  checked={data.use_personal_domain}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Reserve url: example 'https://calendly.com/reserve/reserve-url'"
                name="reserve_url"
                rules={[
                  {
                    required: false,
                    message: "Please enter the reserve url",
                  },
                ]}
              >
                <Input placeholder="Reserve url" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Budget url: example 'https://www.holded.com/es'"
                name="estimate_url"
                rules={[
                  {
                    required: false, 
                    message: "Please enter the budget url",
                  },
                ]}
              >
                <Input placeholder="Budget url" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Custom CTA Text"
                name="cta_custom_text"
                rules={[
                  {
                    required: false, 
                    message: "Please enter the custom CTA text",
                  },
                ]}
              >
                <Input placeholder="Custom CTA text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Custom CTA url: example 'https://www.holded.com/es'"
                name="cta_custom_url"
                rules={[
                  {
                    required: false, 
                    message: "Please enter the Custom CTA url",
                  },
                ]}
              >
                <Input placeholder="Custom CTA url" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Image custom header" name="imagecustomheader">
                <div className={styles.imagePreviewContainer}>
                  {imageURLCustomHeader ? (
                    <img
                      className={styles.imagePreview}
                      src={imageURLCustomHeader}
                      alt="custom header"
                    />
                  ) : null}
                </div>
                <Input type="file" onChange={onImageChangeCustomHeader} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                  label="Custom Color"
                  name="custom_color_web"
                >
                  <Input placeholder="#454B99" value={colorPicker} style={{ marginBottom: "10px" }}/>
                  <SketchPicker color={ colorPicker } onChangeComplete={ handleChangeColorPicker } presetColors={['#454B99', '#4A9F3C', '#713C9B', '#47484E']}/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Terms and conditions"
                name="custom_legal_warning"
                rules={[{ required: false }]}
              >
                <Input.TextArea placeholder="Terms and conditions" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: false }]}
              >
                <Input.TextArea placeholder="description" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Working hours"
                name="working_hours"
                rules={[{ required: false }]}
              >
                <Input.TextArea placeholder="Working hours" />
              </Form.Item>
            </Col>
          </Row>

          {Object.entries(data.business).length ? (
            filteredSubcategories ? (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Category"
                    name="category_id"
                    rules={[
                      {
                        required: false,
                        message: "Please choose the category",
                      },
                    ]}
                  >
                    <Select onChange={handleCategoryChange} showSearch>
                      {(categories || []).map((category: any) => (
                        <Select.Option key={category.id} value={category.id}>
                          {category.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Subcategory" name="subcategory_id">
                    <Select showSearch onChange={handleSubcategoryChange}>
                      {filteredSubcategories.map((subcategory: any) => (
                        <Select.Option
                          key={subcategory.id}
                          value={subcategory.id}
                        >
                          {subcategory.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            ) : null
          ) : null}
          {Object.entries(data.business).length ? (
            filteredServices ? (
              <Form.Item label="Services" name="services_ids">
                <Select
                  showSearch
                  mode="multiple"
                  onChange={handleServicesChange}
                >
                  {filteredServices.map((service: any) => (
                    <Select.Option key={service.id} value={service.id}>
                      {service.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null
          ) : null}
          {Object.entries(data.business).length ? (
            filteredServices ? (
              <Row style={{ marginBottom: 24 }} gutter={16}>
                <Col span={12}>
                  <Button type="primary" onClick={() => setCreateService(true)}>
                    Create service
                  </Button>
                </Col>
              </Row>
            ) : null
          ) : null}
          {createItemService ? (
              <Row style={{ marginBottom: 24 }} gutter={16}>
                <Col span={24}>
                  <input ref={inputRef} className="ant-input" name="inputNewService" placeholder="Service name" />
                </Col>
              </Row>
          ) : null}
          {createItemService ? (
              <Row style={{ marginBottom: 24 }} gutter={16}>
                <Col span={24}>
                  <Space size="small">
                    <Button type="primary" onClick={handleCreateService}>
                      Save
                    </Button>
                    <Button type="ghost" onClick={() => setCreateService(false)}>
                      Cancel
                    </Button>
                  </Space>
                </Col>
              </Row>
          ) : null}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Latitude"
                name="latitude"
                rules={[{ required: false, message: "Please enter latitude" }]}
              >
                <Input placeholder="Latitude" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Longitude"
                name="longitude"
                rules={[{ required: false, message: "Please enter longitude" }]}
              >
                <Input placeholder="Longitude" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Radius"
                name="radius"
                rules={[{ required: false, message: "Please enter radius" }]}
              >
                <Input placeholder="Latitude" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Button onClick={handleShowLocation}>Show Location</Button>
            </Col>
          </Row>
        </Form>
        {Object.entries(data.business).length ? (
          <Button
            style={{ marginTop: 24 }}
            onClick={handleShowRecommendations}
            loading={recommendationsLoading}
          >
            Show recommendations
          </Button>
        ) : null}
        {recommendations ? (
          <Form form={recommendationsForm} component={false}>
            <Table
              style={{ marginTop: 24 }}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns={mergedColumns}
              dataSource={recommendations}
            />
          </Form>
        ) : null}
      </Modal>
    </>
  );
}

export default UserModalForm;