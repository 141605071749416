import React from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { Layout, Menu, Avatar, Row, Col, Dropdown, message } from "antd";
import { UserOutlined, IdcardOutlined, UnorderedListOutlined, LogoutOutlined, WarningOutlined, FormOutlined, PieChartOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";
import logo from "../../assets/recomiendapp-horizontal-logo.png";

import Users from './users/';
import Admins from './admins/';
import Categories from './categories/';
import Subcategories from './subcategories/';
import Services from './services/';
import Feedbacks from './feedbacks';
import Reports from './reports';
import ReportsStories from './reports-stories'
import DeactivatedUsers from './deactivated';
import Recommendations from './recommendations';
import Stats from './stats';
import Stories from './stories';
import StoriesStats from './stories-stats/index';
import ReportsDomains from './reports-domains';
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/auth/actions";


const { Header, Sider, Content } = Layout;

function Dashboard() {
  const dispatch = useDispatch()
  const history = useHistory();

  const onLogout = () => {
    history.push("/login")
    message.success("Has cerrado sesión");
  }

  const logout = () => {
    dispatch(logoutUser(onLogout))
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider breakpoint="lg" className={styles.sider}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <Menu
          theme="dark"
          className={styles.menu}
          mode="inline"
          defaultSelectedKeys={[window.location.pathname.replace("/", "")]}
        >
          <Menu.Item key="admins" icon={<IdcardOutlined />}>
            <Link to="/admins">Admins</Link>
          </Menu.Item>
          <Menu.Item key="user" icon={<UserOutlined />}>
            <Link to="/users">Users</Link>
          </Menu.Item>
          <Menu.Item key="stats" icon={<PieChartOutlined />}>
            <Link to="/stats">Users Stats</Link>
          </Menu.Item>
          <Menu.Item key="categories" icon={<UnorderedListOutlined />}>
            <Link to="/categories">Categories</Link>
          </Menu.Item>
          <Menu.Item key="subcategories" icon={<UnorderedListOutlined />}>
            <Link to="/subcategories">Subcategories</Link>
          </Menu.Item>
          <Menu.Item key="services" icon={<UnorderedListOutlined />}>
            <Link to="/services">Services</Link>
          </Menu.Item>
          <Menu.Item key="feedbacks" icon={<UnorderedListOutlined />}>
            <Link to="/feedbacks">Feedbacks</Link>
          </Menu.Item>
          <Menu.Item key="deactivatedUsers" icon={<UnorderedListOutlined />}>
            <Link to="/users/deactivated">Deactivated users</Link>
          </Menu.Item>
          <Menu.Item key="stories" icon={<UnorderedListOutlined />}>
            <Link to="/stories">Stories</Link>
          </Menu.Item>
          <Menu.Item key="stories-stats" icon={<UnorderedListOutlined />}>
            <Link to="/stories-stats">Stories stats</Link>
          </Menu.Item>
          <Menu.Item key="reports" icon={<WarningOutlined />}>
            <Link to="/reports">Users reports</Link>
          </Menu.Item>
          <Menu.Item key="reports-stories" icon={<WarningOutlined />}>
            <Link to="/reports/stories">Stories reports</Link>
          </Menu.Item>
          <Menu.Item key="reports-domains" icon={<WarningOutlined />}>
            <Link to="/reports/domains">Domains reports</Link>
          </Menu.Item>
          <Menu.Item key="recommendations" icon={<FormOutlined />}>
            <Link to="/recommendations">Recommendations</Link>
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout>

        <Header className={styles.header}>
          <Row justify="end">
            <Col span={1}>
              <Dropdown overlay={
                <Menu>
                  <Menu.Item icon={<LogoutOutlined />} onClick={logout}>
                    Sign out
                  </Menu.Item>
                </Menu>
              }>
                <Avatar size="large" icon={<UserOutlined />} />
              </Dropdown>
            </Col>
          </Row>
        </Header>

        <Content className={styles.content}>
          <Switch>
            <Route path="/users" component={Users} exact/>
            <Route path="/stats" component={Stats} />
            <Route path="/admins" component={Admins} />
            <Route path="/categories" component={Categories} />
            <Route path="/subcategories" component={Subcategories} />
            <Route path="/services" component={Services} />
            <Route path="/feedbacks" component={Feedbacks} />
            <Route path="/reports" exact={true} component={Reports} />
            <Route path="/reports/stories" exact={true} component={ReportsStories} />
            <Route path="/reports/domains" exact={true} component={ReportsDomains} />
            <Route path="/users/deactivated" component={DeactivatedUsers} />
            <Route path="/recommendations" component={Recommendations} />
            <Route path="/stories" exact={true} component={Stories} />
            <Route path="/stories-stats" exact={true} component={StoriesStats} />
          </Switch>
        </Content>

      </Layout>
    </Layout>
  );
}

export default Dashboard;
