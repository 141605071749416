import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, Table, Tag, Input, Space, Popconfirm, Button, message, Row, Col } from 'antd';
import usersConstants from 'redux/users/constants';
import { fetchAllUsers, setLoadingUsers, deleteUser, editUser } from 'redux/users/actions';
import usersServices from 'redux/users/services';
import UserModalForm from 'components/UserModalForm';
import { fetchServices } from '../../../redux/services/actions';
import { fetchCategories } from '../../../redux/categories/actions';
import { fetchSubcategories } from '../../../redux/subcategories/actions';


const { Search } = Input;
const { PAGE_SIZE_OPTIONS, BUSINESS_FILTERS, REGULAR_FILTERS, PREMIUM_FILTERS, TYPE_PROFESSIONAL_FILTERS, TYPE_PROFILE_PICTURE_FILTERS, TYPE_TITLE_FILTERS, PAGE_SIZE } = usersConstants;
const { getStatusColor, getBusinessStatusColor } = usersServices;

function Users() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [tableCount, setTableCount] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [subcategoryFilter, setSubcategoryFilter] = useState();
  const { loading: loadingUsers } = useSelector((state: any) => state.users);
  const { loading: loadingCategories, data: categories } = useSelector((state: any) => state.categories);
  const { loading: loadingSubcategories, data: subcategories } = useSelector((state: any) => state.subcategories);
  const { loading: loadingServices, data: services } = useSelector((state: any) => state.services);
  const [load, setLoad] = useState(true);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      fixed: 'left' as const,
      width: 260,
      onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
      sorter: (a: any, b: any) => {
        if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
          return -1;
        }
        if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
          return 1;
        }
        if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) {
          return -1;
        }
        if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      render: (text: any, record: any) => {
        const firstName = record.first_name;
        const lastName = record.last_name;
        return `${firstName} ${lastName}`;
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      width: 260,
      sorter: (a: any, b: any) => {
        if (a.created_at < b.created_at) {
          return -1;
        }
        if (a.created_at > b.created_at) {
          return 1;
        }
        return 0;
      },
      render: (text: any, record: any) => {
        const date = new Date(record.created_at);
        return date.toString();
      },
    },
    {
      title: 'Phone number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      
      onFilter: (value: any, record: any) => record.phone_number.indexOf(value) === 0,
      sorter: (a: any, b: any) => parseInt(a.phone_number) - parseInt(b.phone_number),
    },
    {
      title: 'Regular',
      dataIndex: 'status',
      key: 'status',
      render: (status: string, { id }: { id: string }) => {
        if (!status) return;
        let color = getStatusColor(status);
        return (
          <Tag color={color} key={id + '-' + status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
      filters: REGULAR_FILTERS,
      filterMultiple: false,
      onFilter: (value: any, record: any) => record.status === value,
    },
    {
      title: 'Business',
      dataIndex: 'business_status',
      key: 'business_status',
      width: 200,
      render: (text: string, record: any) => {
        const business_status = record.business && record.business.status;
        if (!business_status) return;
        let color = getBusinessStatusColor(business_status);
        return (
          <Tag color={color} key={record.id + '-' + business_status}>
            {business_status.toUpperCase()}
          </Tag>
        );
      },
      filters: BUSINESS_FILTERS,
      filterMultiple: false,
      onFilter: (value: any, record: any) => record.business && record.business.status === value,
    },
    {
      title: 'Premiun',
      dataIndex: 'premiun',
      key: 'premiun',
      ellipsis: true,
      render: (text: string, record: any) => {
        const premiun_status = record.premiun;
        
        let color = 'lightgrey';
        let customtext = 'NO CLIENT';
        if (premiun_status) {
          color = 'green';
          customtext = 'CLIENT';
        }
        return (
          <Tag color={color} key={record.id + '-' + premiun_status}>
            {customtext}
          </Tag>
        );
      },
      filterMultiple: false,
      filters: PREMIUM_FILTERS,
      onFilter: (value: any, record: any) => record.premiun ? record.premiun === value : false === value,
    },
    {
      title: 'Type',
      dataIndex: 'use_personal_domain',
      key: 'use_personal_domain',
      
      render: (text: string, record: any) => {
        const use_personal_domain_status = record.use_personal_domain;
        
        let color = 'lightgrey';
        let customtext = 'BASIC';
        if (use_personal_domain_status) {
          color = 'green';
          customtext = 'PRO';
        }
        return (
          <Tag color={color} key={record.id + '-' + use_personal_domain_status}>
            {customtext}
          </Tag>
        );
      },
      filterMultiple: false,
      filters: TYPE_PROFESSIONAL_FILTERS,
      onFilter: (value: any, record: any) => record.use_personal_domain ? record.use_personal_domain === value : false === value,
    },
    {
      title: 'Profile picture',
      dataIndex: 'image',
      key: 'image',
      
      render: (text: string, record: any) => {
        const image_status = record.image;
        
        let color = 'lightgrey';
        let customtext = 'NO';
        if (image_status != null) {
          color = 'green';
          customtext = 'YES';
        }
        return (
          <Tag color={color} key={record.id + '-' + image_status}>
            {customtext}
          </Tag>
        );
      },
      filterMultiple: false,
      filters: TYPE_PROFILE_PICTURE_FILTERS,
      onFilter: (value: any, record: any) => record.image ? record.image.toString().toLowerCase().includes(value) : 'null' === value,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      render: (text: string, record: any) => {
        const title_status = record.title;
        
        let color = 'lightgrey';
        let customtext = 'NO';
        if (title_status != null) {
          color = 'green';
          customtext = 'YES';
        }
        return (
          <Tag color={color} key={record.id + '-' + title_status}>
            {customtext}
          </Tag>
        );
      },
      filterMultiple: false,
      filters: TYPE_TITLE_FILTERS,
      onFilter: (value: any, record: any) => {
        console.log(record.title)
        const auxTitle = record.title == null ? 'null' : '.'
        
        return auxTitle && auxTitle === value
      },
    },
    {
      title: 'Categories',
      dataIndex: 'category_id',
      key: 'category_id',
      render: (text: string, record: any) => {
        const id = record.category_id;
        let color = 'blue';
        const newArray = categories.filter(function (category: any) {
          return category.id === id;
        });
       
        if (newArray.length > 0){
          const customtext = newArray[0].name;
          return (
            <Tag color={color} key={record.id + '-' + id}>
              {customtext}
            </Tag>
          );
        } else{
          return(<></>)
        }
      },
      onFilter: (value: any, record: any) => {
        return record.category_id &&  record.category_id.indexOf(value) === 0;
      },
      filters: categories && categories.map((category: any) => ({ text: category.name, value: category.id })),
    },
    {
      title: 'Subcategories',
      dataIndex: 'subcategory_id',
      key: 'subcategory_id',
      render: (text: string, record: any) => {
        const id = record.subcategory_id;
        let color = 'blue';
        const newArray = subcategories.filter(function (subcategory: any) {
          return subcategory.id === id;
        });
        if (newArray.length > 0){
          const customtext = newArray[0].name;
          return (
            <Tag color={color} key={record.id + '-' + id}>
              {customtext}
            </Tag>
          );
        } else{
          return(<></>)
        }
      },
      onFilter: (value: any, record: any) => {
        return record.subcategory_id &&  record.subcategory_id.indexOf(value) === 0;
      },
      filters: subcategoryFilter,
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right' as const,
      width: 180,
      render: (row: any) => (
        <Space>
          <UserModalForm
            openButtonText="Edit"
            modalTitle="Edit user"
            successMessage="User edited successfully"
            itemId={row.id}
            onConfirm={editItem}
            categories={categories}
            subcategories={subcategories}
            services={services}
            data={row}
          />
          <Popconfirm title="Confirm" okText="Yes" cancelText="No" onConfirm={() => deleteItem(row.id)}>
            <Button danger>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const editItem = (id: string, data: any, callback: any) => {
    editUser(id, data)
      .then(() => {
        callback();
        fetchUsers();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const fetchUsers = () => {
    setLoad(true);
    dispatch(setLoadingUsers(true));
    fetchAllUsers().then((data: any) => {
      if (data?.data?.users) {
        setData(data.data.users);
        setFilteredData(data.data.users);
        dispatch(setLoadingUsers(false));
        setLoad(false);
      }
    });
  };

  const createSubcategoryFilter = () => {
    let aux:any[] = [];
    categories.sort(function (a:any, b:any) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }).map(function(category:any) {
      console.log(category);
      category.subcategories.sort(function (a:any, b:any) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }).map(function (subcategory:any) {
        aux.push({ text: category.name + ' - ' + subcategory.name, value: subcategory.id });
      });
    });
    setSubcategoryFilter(aux);
  };

  useEffect(() => {
    fetchUsers();
  }, [dispatch]);

  useEffect(() => {
    fetchUsers();
    dispatch(fetchCategories());
    dispatch(fetchSubcategories());
    dispatch(fetchServices());
  }, [dispatch]);

  useEffect(() => {
    createSubcategoryFilter();
  }, [subcategories]);

  const deleteItem = (id: string) => {
    deleteUser(id)
      .then(() => {
        message.success('User deleted successfully');
        fetchUsers();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const onSearch = (value: any) => {
    if (!value.length) fetchUsers();

    const lowerCaseValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const filteredData = data.filter(
      (row: any) =>
        `${row.first_name} ${row.last_name}`.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(lowerCaseValue) || row.phone_number.toLowerCase().includes(lowerCaseValue)
        
    );
    setFilteredData(filteredData);
  };

  const onChange = (e: any) => {
    const value = e.target.value;
    if (!value.length) fetchUsers();
  };
  return (
    
    <div>
      <PageHeader
        title="Users"
        ghost={false}
        extra={[<Search placeholder="Search users" enterButton allowClear onSearch={onSearch} style={{ width: 350 }} onChange={onChange} />]}
      />
      <div className="content_container">
        {subcategoryFilter ? 
        <>
        <Row justify="end" style={{paddingBottom:'7px'}}><Col span={4}></Col><Tag style={{marginRight:'0px', color:'#595959'}} color="">TOTAL: {tableCount}</Tag></Row>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true, showTotal: (total, range) => {
            setTableCount(total); // store the total count in a hook
            return ``; // return an empty string unless you want to show it next to the pagination
          }, }}
          loading={load}
          scroll={{ x: 2500, y: 600 }}
          
        />
        </>: <></> }
      </div>
    </div>
  );
}

export default Users;