import api from '../../helpers/api';
import usersConstants from './constants';

const { TYPE, STATUS, BUSINESS_STATUS } = usersConstants;

const usersServices = {
  fetchAll: () => {
    return api.get('/users/all');
  },
  fetchByNumber: (phoneNumber: string) => {
    return api.get(`/users?phone_number=${encodeURIComponent(phoneNumber)}`);
  },
  delete: (id: string) => {
    return api.delete(`/users/${id}`);
  },
  edit: (id: string, data: any) => {
    return api.put(`/users/${id}`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  getRecommendations: (id: string) => {
    return api.get(`/recommendations/recieved?id=${id}`);
  },
  getTypeColor: (account_type: String) => {
    switch (account_type) {
      case TYPE.REGULAR:
        return 'green';
      case TYPE.BUSINESS:
        return 'geekblue';
      default:
        break;
    }
  },
  getStatusColor: (status: String) => {
    switch (status) {
      case STATUS.REJECTED:
        return 'red';
      case STATUS.INACTIVE:
        return 'lightgrey';
      case STATUS.ACTIVE:
        return 'green';
      case STATUS.CANCELED:
        return 'orange';
      case STATUS.DELETED:
        return 'black';
      default:
        break;
    }
  },
  getBusinessStatusColor: (businessStatus: String) => {
    switch (businessStatus) {
      case BUSINESS_STATUS.REJECTED:
        return 'red';
      case BUSINESS_STATUS.DEACTIVATED:
        return 'orange';
      case BUSINESS_STATUS.ACTIVE:
        return 'green';
      case BUSINESS_STATUS.INACTIVE:
        return 'lightgrey';
      case BUSINESS_STATUS.PENDING_ACCEPTANCE:
        return 'orange';
      default:
        break;
    }
  },
};

export default usersServices;
