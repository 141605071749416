import api from '../../helpers/api';

const reportsServices = {
  fetch: () => {
    return api.get('/stories');
  },
  deleteStorie: (storie_id: String) => {
    return api.delete(`/stories/${storie_id}`);
  },
  updateStorie: (data: Object) => {
    return api.put(`/stories/`, data);
  },
  fetchStats: () => {
    return api.get('/stories/stats');
  },
  blockReportedUser: (reciever_id: String, type: String) => {
    return api.put(`/users/reject/${reciever_id}`, { type });
  },
  unblockReportedUser: (reciever_id: String, type: String, report_id: String) => {
    return api.put(`/users/unblock/${reciever_id}`, { type, report_id });
  },
};

export default reportsServices;
