import React, { useEffect, useState } from 'react';
import { PageHeader, Table, Button, Input, Popconfirm, Space, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { createSubcategory, deleteSubcategory, fetchSubcategories, editSubcategory, fetchAllSubcategories } from 'redux/subcategories/actions';
import { fetchCategories } from 'redux/categories/actions';
import SubcategoriesModalForm from 'components/SubcategoriesModalForm';
import subcategoriesConstants from 'redux/subcategories/constants';

const { PAGE_SIZE, PAGE_SIZE_OPTIONS } = subcategoriesConstants;
const { Search } = Input;
function Subcategories() {
  const dispatch = useDispatch();
  const { loading: loadingSubcategories, data: subcategories } = useSelector((state: any) => state.subcategories);
  const { loading: loadingCategories, data: categories } = useSelector((state: any) => state.categories);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [load, setLoad] = useState(true);


  useEffect(() => {
    fetchSubcategoriesAll();
  }, [dispatch]);
  // On first load, fetch data
  useEffect(() => {
    fetchSubcategoriesAll();
    dispatch(fetchSubcategories());
    dispatch(fetchCategories());

  }, [dispatch]);

  const createItem = (data: { name: string; image: string }, callback: any) => {
    dispatch(
      createSubcategory(data, () => {
        callback();
        dispatch(fetchSubcategories());
      })
    );
  };

  const editItem = (id: string, data: { name: string; image: string }, callback: any) => {
    dispatch(
      editSubcategory(id, data, () => {
        callback();
        dispatch(fetchSubcategories());
      })
    );
  };

  const deleteItem = (id: string) => {
    dispatch(
      deleteSubcategory(id, () => {
        message.success('Subcategory deleted successfully');
        dispatch(fetchSubcategories());
      })
    );
  };
  const onSearch = (value: any) => {
    if (!value.length) fetchSubcategoriesAll();
    const lowerCaseValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const filteredData = subcategories.filter(
      (row: any) =>
        `${row.name}`.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(lowerCaseValue)
        
    );
    setFilteredSubcategories(filteredData);

  };
  const onChange = (e: any) => {
    const value = e.target.value;
    if (!value.length) fetchSubcategoriesAll();
  };

  const fetchSubcategoriesAll = () => {
    setLoad(true);
    fetchAllSubcategories().then((data: any) => {
      if (data?.data?.subcategories) {
        setFilteredSubcategories(data.data.subcategories);
        setLoad(false);
      }
    });
  };

  return (
    <div>
      <PageHeader
        title='Subcategories'
        ghost={false}
        extra={[
          <Search 
            placeholder="Search subcategories" 
              enterButton 
              allowClear 
              onSearch={onSearch} 
              style={{ width: 350 }} 
              onChange={onChange} 
          />,
          <SubcategoriesModalForm
            openButtonText='Create'
            modalTitle='Create subcategory'
            successMessage='Subcategory created successfully'
            onConfirm={createItem}
            subcategories={subcategories}
            categories={categories}
          />
      ]}
      />
      <div className='content_container'>
        <Table
          dataSource={filteredSubcategories}
          loading={load || loadingSubcategories || loadingCategories}
          rowKey='id'
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              sorter: (a: any, b: any) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              },
            },
            {
              title: 'Category',
              dataIndex: 'category',
              key: 'category',
              render: (text: any, record: any) => {
                const obj = categories.filter(function(item: any) {
                  return item.id === record.category_id
                });  
                const name = obj[0].name;
                return `${name}`;
              },
              onFilter: (value: any, record: any) => {
                const obj = categories.filter(function(item: any) {
                  return item.id === record.category_id
                }); 
                return record.category_id && obj[0].name.indexOf(value) === 0;
              },
              filters: categories && categories.map((category: any) => ({ text: category.name, value: category.name })),
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (row) => (
                <Space>
                  <SubcategoriesModalForm
                    openButtonText='Edit'
                    modalTitle='Edit subcategory'
                    successMessage='Subcategory edited successfully'
                    itemId={row.id}
                    onConfirm={editItem}
                    subcategories={subcategories}
                    categories={categories}
                    data={row}
                  />
                  <Popconfirm title='Confirm' okText='Yes' cancelText='No' onConfirm={() => deleteItem(row.id)}>
                    <Button danger>Delete</Button>
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Subcategories;