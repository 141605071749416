import React, { useEffect, useState } from 'react';
import { PageHeader, Table, Button, Tag, Space, Popconfirm, Input, message, Modal } from 'antd';
import { useSelector } from 'react-redux';
import reportsConstants from 'redux/reports/constants';
import { setLoadingReports, setReportsStats, fetchStats } from 'redux/stories/actions';
import store from 'redux/rootStore';


const { PAGE_SIZE_OPTIONS, PAGE_SIZE } = reportsConstants;
const { Search } = Input;


const fetchReportsHandler = () => {
  const { dispatch } = store;
  dispatch(setLoadingReports(true));
  fetchStats().then((data: any) => {
    if (data?.data?.stories_stats) {
      dispatch(setReportsStats(data.data.stories_stats));
      dispatch(setLoadingReports(false));
    }
  });
};

function StoriesStats() {
  
  const { loading, dataStats } = useSelector((state: any) => state.stories);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchReportsHandler();
  }, []);

  useEffect(() => {
    setFilteredData(dataStats);
  }, [dataStats]);

  const onSearch = (value: any) => {
    if (!value.length) fetchReportsHandler();
    const lowerCaseValue = value.toLowerCase();
    const filteredData = dataStats.filter(
      (row: any) =>
        row.name.toLowerCase().includes(lowerCaseValue) ||
        row.content.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };

  const onChange = (e: any) => {
    const value = e.target.value;
    if (!value.length) fetchReportsHandler();
  };


 const columns: any = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
    sorter: (a: any, b: any) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    render: (text: any, record: any) => {
      return record.name;
    },
    width: '20%',
  },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
    onFilter: (value: any, record: any) => record.content.indexOf(value) === 0,
    sorter: (a: any, b: any) => parseInt(a.content) - parseInt(b.content),
    render: (text: any, record: any) => record.content,
    width: '50%',
  },
  {
    title: 'Text color',
    dataIndex: 'color_text',
    key: 'color_text',
    width: '10%',
    render: (text: any, record: any) => {
       return <div style={{ height: 25, width: 25, border: '1px solid grey', backgroundColor: record.color_text }}></div>
    }
  },
  {
    title: 'Background color',
    dataIndex: 'color_background',
    key: 'color_background',
    width: '10%',
    render: (text: any, record: any) => {
        return <div style={{ height: 25, width: 25, border: '1px solid grey', backgroundColor: record.color_background }}></div>
     }
  },
  {
    title: 'Views',
    dataIndex: 'views',
    key: 'views',
    onFilter: (value: any, record: any) => record.views.indexOf(value) === 0,
    sorter: (a: any, b: any) => parseInt(a.views) - parseInt(b.views),
  },
  {
    title: 'Replies',
    dataIndex: 'replies',
    key: 'replies',
    onFilter: (value: any, record: any) => record.replies.indexOf(value) === 0,
    sorter: (a: any, b: any) => parseInt(a.replies) - parseInt(b.replies),
  },
];

  return (
    <div>
      <PageHeader
        title='Stories stats'
        ghost={false}
        extra={[
          <Search 
            placeholder='Search storie' 
            enterButton allowClear 
            onSearch={onSearch} 
            style={{ width: 350 }}
            onChange={onChange}
          />
        ]}
      />
      <div className='content_container'>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          loading={loading}
          scroll={{ x: 1500 }}
          rowKey={(record: any) => record.id}
        />
      </div>
    </div>
  );
}

export default StoriesStats;
