const usersConstants = {
  SET_LOADING_USERS: 'SET_LOADING_USERS',
  ERRORS: {
    NUMBER_EXISTS: 'This number already exists'
  },
  STATUS: {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    CANCELED: 'canceled',
    DELETED: 'deleted',
    REJECTED: 'rejected',
  },
  BUSINESS_STATUS: {
    REJECTED: 'rejected',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    DEACTIVATED: 'deactivated',
    PENDING_ACCEPTANCE: 'pending_acceptance',
  },
  TYPE: {
    BUSINESS: 'business',
    REGULAR: 'regular',
  },
  PAGE_SIZE: 50,
  PAGE_SIZE_OPTIONS: ['10', '25', '50', '100'],
  BUSINESS_FILTERS: [
    {
      text: 'Rejected',
      value: 'rejected',
    },
    {
      text: 'Deactivated',
      value: 'deactivated',
    },
    {
      text: 'Active',
      value: 'active',
    },
    {
      text: 'Inactive',
      value: 'inactive',
    },
    {
      text: 'Pending acceptance',
      value: 'pending_acceptance',
    },
  ],
  REGULAR_FILTERS: [
    {
      text: 'Active',
      value: 'active',
    },
    {
      text: 'Inactive',
      value: 'inactive',
    },
    {
      text: 'Rejected',
      value: 'rejected',
    },
    {
      text: 'Canceled',
      value: 'canceled',
    },
    {
      text: 'Deleted',
      value: 'deleted',
    },
  ],
 PREMIUM_FILTERS: [
    {
      text: 'CLIENT',
      value: true,
    },
    {
      text: 'NO CLIENT',
      value: false,
    },
  ],
  TYPE_PROFESSIONAL_FILTERS: [
    {
      text: 'PRO',
      value: true,
    },
    {
      text: 'BASIC',
      value: false,
    },
  ],
  TYPE_PROFILE_PICTURE_FILTERS: [
    {
      text: 'YES',
      value: '.',
    },
    {
      text: 'NO',
      value: 'null',
    },
  ],
  TYPE_TITLE_FILTERS: [
    {
      text: 'YES',
      value: '.',
    },
    {
      text: 'NO',
      value: 'null',
    },
  ],
  TYPE_FILTERS: [
    {
      text: 'Business',
      value: 'business',
    },
    {
      text: 'Regular',
      value: 'regular',
    },
  ],
};

export default usersConstants;
