import React, { useEffect, useState } from 'react';
import { PageHeader, Table, Button, Tag, Space, Popconfirm, Input } from 'antd';
import { useSelector } from 'react-redux';
import reportsConstants from 'redux/reports/constants';
import usersConstants from 'redux/users/constants';
import { fetchReports, setLoadingReports, blockReportedUser, unblockReportedUser, setReports } from 'redux/reports/actions';
import usersServices from 'redux/users/services';
import store from 'redux/rootStore';

const { PAGE_SIZE_OPTIONS, PAGE_SIZE } = reportsConstants;
const { BUSINESS_STATUS, STATUS, TYPE, REGULAR_FILTERS, BUSINESS_FILTERS } = usersConstants;
const { getStatusColor, getBusinessStatusColor } = usersServices;
const { Search } = Input;

const columns: any = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
    sorter: (a: any, b: any) => {
      if (a.reciever.first_name.toLowerCase() < b.reciever.first_name.toLowerCase()) {
        return -1;
      }
      if (a.reciever.first_name.toLowerCase() > b.reciever.first_name.toLowerCase()) {
        return 1;
      }
      if (a.reciever.last_name.toLowerCase() < b.reciever.last_name.toLowerCase()) {
        return -1;
      }
      if (a.reciever.last_name.toLowerCase() > b.reciever.last_name.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    render: (text: any, record: any) => {
      const firstName = record.reciever.first_name;
      const lastName = record.reciever.last_name;
      return `${firstName} ${lastName}`;
    },
    width: 200,
  },
  {
    title: 'Phone number',
    dataIndex: 'phone_number',
    key: 'phone_number',
    onFilter: (value: any, record: any) => record.phone_number.indexOf(value) === 0,
    sorter: (a: any, b: any) => parseInt(a.reciever.phone_number) - parseInt(b.reciever.phone_number),
    render: (text: any, record: any) => record.reciever.phone_number,
    width: 150,
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    sorter: (a: any, b: any) => {
      if (a.comment.toLowerCase() < b.comment.toLowerCase()) {
        return -1;
      }
      if (a.comment.toLowerCase() > b.comment.toLowerCase()) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Reported by',
    dataIndex: 'reported_by',
    key: 'reported_by',
    sorter: (a: any, b: any) => {
      if (a.user.first_name.toLowerCase() < b.user.first_name.toLowerCase()) {
        return -1;
      }
      if (a.user.first_name.toLowerCase() > b.user.first_name.toLowerCase()) {
        return 1;
      }
      if (a.user.last_name.toLowerCase() < b.user.last_name.toLowerCase()) {
        return -1;
      }
      if (a.user.last_name.toLowerCase() > b.user.last_name.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    render: (text: any, record: any) => {
      const firstName = record.user.first_name;
      const lastName = record.user.last_name;
      const phoneNumber = record.user.phone_number;
      return `${firstName} ${lastName}, ${phoneNumber}`;
    },
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: (a: any, b: any) => {
      const firstTime = new Date(a.created_at).getTime();
      const secondTime = new Date(b.created_at).getTime();
      return secondTime - firstTime;
    },
    render: (text: any, record: any) => {
      const date = new Date(record.created_at);
      return date.toString();
    },
  },
  {
    title: 'Regular',
    dataIndex: 'status',
    key: 'status',
    render: (text: string, record: any) => {
      const status = record.reciever.status;
      if (!status) return;
      let color = getStatusColor(status);
      return (
        <Tag color={color} key={record.reciever.id + '-' + status}>
          {status.toUpperCase()}
        </Tag>
      );
    },
    filters: REGULAR_FILTERS,
    filterMultiple: false,
    onFilter: (value: any, record: any) => record.reciever.status === value,
  },
  {
    title: 'Business',
    dataIndex: 'business_status',
    key: 'business_status',
    render: (text: string, record: any) => {
      const business_status = record.reciever.business && record.reciever.business.status;
      if (!business_status) return;
      let color = getBusinessStatusColor(business_status);
      return (
        <Tag color={color} key={record.reciever.id + '-' + business_status}>
          {business_status.toUpperCase()}
        </Tag>
      );
    },
    filters: BUSINESS_FILTERS,
    filterMultiple: false,
    onFilter: (value: any, record: any) => record.reciever.business && record.reciever.business.status === value,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: any, record: any) => <Actions data={record} />,
    fixed: 'right',
    width: 200,
  },
];

const BlockUserToggle = ({ toggleCondition, data, type }: any) => {
  const disabledUnblock = type === TYPE.BUSINESS && data.reciever.status === STATUS.REJECTED;
  return (
    <>
      {toggleCondition ? (
        <Popconfirm
          title='Are you sure you want to unblock an already rejected user?'
          okText='Yes'
          cancelText='No'
          disabled={disabledUnblock}
          onConfirm={() => unblockReportedUserHandler(data.reciever_id, data.type, data.id)}
        >
          <Button type='primary' disabled={disabledUnblock}>Unblock {type}</Button>
        </Popconfirm>
      ) : (
        <Button type='primary' danger onClick={() => blockReportedUserHandler(data.reciever_id, data.type)}>
          Block {type}
        </Button>
      )}
    </>
  );
};

const Actions = ({ data }: any) => {
  const isBusinessRejected = data.reciever.business && data.reciever.business.status === BUSINESS_STATUS.REJECTED;
  const isRegularRejected = data.reciever.status === STATUS.REJECTED;
  const reportType = data.type;
  return (
    <Space>
      {reportType === TYPE.BUSINESS ? (
        <BlockUserToggle toggleCondition={isBusinessRejected} data={data} type={reportType} />
      ) : (
        <BlockUserToggle toggleCondition={isRegularRejected} data={data} type={reportType} />
      )}
    </Space>
  );
};

const blockReportedUserHandler = (reciever_id: String, type: String) => {
  blockReportedUser(reciever_id, type).then(fetchReportsHandler);
};

const unblockReportedUserHandler = (reciever_id: String, type: String, report_id: String) => {
  unblockReportedUser(reciever_id, type, report_id).then(fetchReportsHandler);
};

const fetchReportsHandler = () => {
  const { dispatch } = store;

  dispatch(setLoadingReports(true));
  fetchReports().then((data: any) => {
    if (data?.data?.reports) {
      dispatch(setReports(data.data.reports));
      dispatch(setLoadingReports(false));
    }
  });
};

function Reports() {
  const { loading, data } = useSelector((state: any) => state.reports);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchReportsHandler();
  }, []);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const onSearch = (value: any) => {
    if (!value.length) fetchReportsHandler();
    const lowerCaseValue = value.toLowerCase();
    const filteredData = data.filter(
      (row: any) =>
        `${row.reciever.first_name} ${row.reciever.last_name}`.toLowerCase().includes(lowerCaseValue) ||
        row.reciever.phone_number.toLowerCase().includes(lowerCaseValue) ||
        row.comment.toLowerCase().includes(lowerCaseValue) ||
        `${row.user.first_name} ${row.user.last_name}`.toLowerCase().includes(lowerCaseValue) ||
        row.user.phone_number.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };

  const onChange = (e: any) => {
    const value = e.target.value;
    if (!value.length) fetchReportsHandler();
  };

  return (
    <div>
      <PageHeader
        title='Users reports'
        ghost={false}
        extra={[<Search placeholder='Search user reports' enterButton allowClear onSearch={onSearch} style={{ width: 350 }} onChange={onChange} />]}
      />
      <div className='content_container'>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          loading={loading}
          scroll={{ x: 1500 }}
        />
      </div>
    </div>
  );
}

export default Reports;
