import api from "../../helpers/domainApi";

export const customDomainServices = {
	createDomain: (domain: string, userId: string) => {
		return api.post("/createDomain", { domain, userId });
	},
	removeDomain: (domain: string) => {
		return api.post("/removeDomain", { domain });
	}
}

