import React, { useState } from 'react'
import { Form, Input, Button, Result } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

import styles from "./styles.module.scss";
import logo from '../../assets/logo-recomiendapp-white.png';
import authServices from '../../redux/auth/services';


function RecoverRequest() {
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(undefined);
	const [loading, setLoading] = useState(false);

	const onFinish = async ({ email } : { email: string }) => {
		try {
			setError(undefined);
			setLoading(true);
			await authServices.requestRecovery(email);
			setSuccess(true);
			setLoading(false);
		} catch (err) {
			setError(err.response.data.errors.message);
			setLoading(false);
		}
	};

	if (success) {
		return (
			<Result
				status="success"
				title="A recovery link must have arrived in your email"
				style={{ paddingTop: 200 }}
				extra={[
					<Link to="/login">
						<Button type="primary" key="login">
              Return to main page
						</Button>
					</Link>
				]}
			/>
		);
	}

	return (
    <div className={styles.login_form_container}>
      <img src={logo} alt="Logo" className={styles.login_form_logo} />
      <Form name="recovery_request" onFinish={onFinish}>
        <Form.Item
          label="Enter the email of the account to recover"
          name="email"
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Enter a valid email" },
          ]}
					validateStatus={error && "error"}
					help={error || null}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
            disabled={loading}
          />
        </Form.Item>

        <Form.Item>
          <Link className={styles.login_form_forgot} to="/login">
            Have an account?
          </Link>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login_form-submit"
            disabled={loading}
          >
            Send
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default RecoverRequest;

