import React, { useEffect, useState } from 'react'
import {
    Modal,
    Form,
    Input,
    Button,
} from 'antd';
import { HexColorPicker } from "react-colorful";

export default function ModalForm({ type, open, onClose, data, onEdit } : { type: String, open: any, onClose: () => void, data: any, onEdit: (payload: object) => void }) {

    const [isModalVisible, setModalVisible] = useState<boolean | undefined>(false);
    const [colorText, setColorText] = useState("#aabbcc");
    const [colorBackground, setColorBackground] = useState("#aabbcc");
    const [dataEdit, setData] = useState({});
    const [form] = Form.useForm();

    const handleCancel = () => {
        onClose();
    }

    useEffect(() => {
        setModalVisible(open);
    },[open]);

    useEffect(() => {
       if (data) {
          setColorBackground(data.color_background);
          setColorText(data.color_text);
          form.setFieldsValue({
            content: data.content,
         });
       }
    },[data]);

    const onFinish = (values: any) => {
        let payload = {
            ...values,
            color_background: colorBackground,
            color_text: colorText,
            storie_id: data.id,
        };
        onEdit(payload);
    }

    return (
        <Modal 
            title={`${type === 'I' ? 'Create' : 'Edit'} storie`} 
            visible={isModalVisible} 
            onCancel={handleCancel}
            footer={[
                <Button key="edit" type='primary' onClick={form.submit}>
                  Edit
                </Button>,
                <Button key="Cancel" onClick={handleCancel}>
                  Cancel
                </Button>
              ]}
        >
             <Form
                layout="vertical"
                onValuesChange={() => console.log()}
                size={'small'}
                form={form}
                onFinish={onFinish}
             >
                <Form.Item
                    name="content"
                    label="Content"
                    rules={[{ required:true }]}
                >
                    <Input.TextArea />
                </Form.Item>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Form.Item label="Color text">
                        <HexColorPicker key={0} color={colorText} onChange={setColorText} />
                    </Form.Item>
                    <Form.Item label="Color background ">
                        <HexColorPicker key={1} color={colorBackground} onChange={setColorBackground} />
                    </Form.Item>
                </div>
             </Form>
        </Modal>
    )
}
