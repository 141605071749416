import api from '../../helpers/api';

const subcategoriesServices = {
  fetchAll: () => {
    return api.get('/subcategories');
  },
  create: (data: { name: string; image?: string }) => {
    return api.post('/subcategories/', data, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  edit: (id: string, data: any) => {
    return api.put('/subcategories/' + id, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  delete: (id: string) => {
    return api.delete('/subcategories/' + id);
  },
};

export default subcategoriesServices;
