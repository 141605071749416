import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, message, Select } from 'antd';

function ServicesModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  successMessage?: string;
  categories?: any[];
  itemId?: string;
  subcategories?: any[];
  data?: any;
}) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [categoryId, setCategoryId] = useState('');
  const [filteredSubcategories, setFilteredSubcategories]: any = useState([]);
  const { modalTitle, openButtonText, onConfirm, successMessage, itemId, categories, subcategories, data } = props;

  useEffect(() => {
    setCategoryId(data && data.category_id);
  }, [data]);

  useEffect(() => {
    const filtered: any = (subcategories || []).filter((subcategory: any) => subcategory.category_id === categoryId);
    form.resetFields(['subcategory']);
    setFilteredSubcategories(filtered);
  }, [subcategories, categoryId]);

  const openModal = () => {
    form.setFieldsValue(data);
    setCategoryId(data && data.category_id);
    setVisible(true);
  };
  const onCancel = () => setVisible(false);
  const onSuccess = () => {
    setVisible(false);
    form.resetFields(['subcategory_id', 'name', 'category_id']);
    message.success(successMessage || 'Exit');
  };
  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (itemId) {
          onConfirm(itemId, values, onSuccess);
        } else {
          onConfirm(values, onSuccess);
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCategoryChange = (value: any) => {
    setCategoryId(value);
    form.setFieldsValue({ subcategory_id: null });
  };

  return (
    <>
      <Button type='primary' onClick={openModal}>
        {openButtonText}
      </Button>
      <Modal title={modalTitle} visible={visible} onOk={onOk} onCancel={onCancel}>
        {filteredSubcategories ? (
          <Form layout='vertical' form={form}>
            {/* NAME */}
            <Form.Item label='Name' name='name' rules={[{ required: true, message: 'Please enter the name' }]}>
              <Input placeholder='Name' />
            </Form.Item>
            {/* CATEGORY */}
            <Form.Item label='Category' name='category_id' rules={[{ required: true, message: 'Please choose the category' }]}>
              <Select onChange={handleCategoryChange} showSearch>
                {(categories || []).map((category: any) => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* SUBCATEGORY*/}
            <Form.Item label='Subcategory' name='subcategory_id'>
              <Select showSearch>
                {filteredSubcategories.map((subcategory: any) => (
                  <Select.Option key={subcategory.id} value={subcategory.id}>
                    {subcategory.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );
}

export default ServicesModalForm;
