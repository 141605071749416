import React, { useState, useEffect } from "react";
import { Form, Input, Button, Result, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Link, useParams, useHistory } from "react-router-dom";

import styles from "./styles.module.scss";
import logo from '../../assets/logo-recomiendapp-white.png';
import authServices from "../../redux/auth/services";


function RecoverToken() {
	const { token } : any = useParams();
	const history = useHistory();
	const [checking, setChecking] = useState(false);
	const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		const checkToken = async () => {
			if (!checking) {
				try {
					setError(undefined);
					setLoading(true);
					await authServices.checkRecovery(token);
					setLoading(false);
					setChecking(false)
				} catch (err) {
					history.push("/login");
					message.error(err.response.data.errors.message);
					setLoading(false);
					setChecking(false)
				}
      }
		}
		checkToken();
	}, [token, checking, history])

	const onFinish = async ({ password }: { password: string }) => {
		try {
      setError(undefined);
      setLoading(true);
      await authServices.postRecovery(token, password);
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(err.response.data.errors.message);
      setLoading(false);
    }
	};

	if (success) {
		return (
			<Result
				status="success"
				title="Password changed successfully"
				style={{ paddingTop: 200 }}
				extra={[
					<Link to="/login">
						<Button type="primary" key="login">
							Return to main page
						</Button>
					</Link>,
				]}
			/>
		);
	}

	return (
		<div className={styles.login_form_container}>
			<img src={logo} alt="Logo" className={styles.login_form_logo} />
			<Form
				name="post_token"
				onFinish={onFinish}
			>
				<Form.Item
					name="password"
					rules={[{ required: true, message: "Please enter your new password" }]}
					validateStatus={error && "error"}
					help={error || null}
				>
					<Input
						prefix={<LockOutlined className="site-form-item-icon" />}
						type="password"
						placeholder="New password"
						disabled={loading}
					/>
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="login_form-submit"
						disabled={loading}
					>
						Send
          </Button>
				</Form.Item>
			</Form>
		</div>
	);
}

export default RecoverToken;

