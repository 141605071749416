import api from '../../helpers/api';

const reportsServices = {
  fetch: () => {
    return api.get('/reports');
  },
  blockReportedUser: (reciever_id: String, type: String) => {
    return api.put(`/users/reject/${reciever_id}`, { type });
  },
  unblockReportedUser: (reciever_id: String, type: String, report_id: String) => {
    return api.put(`/users/unblock/${reciever_id}`, { type, report_id });
  },
};

export default reportsServices;
