export default (data: any) => {
  return data.map((record: any, index: number) => {
    let owner;
    let reciever_first_name;
    let reciever_last_name;
    let reciever_number;
    let title;
    let category;
    let subcategory;
    let comment;
    let services;
    const { owner: userOwner, reciever: userReciever, comment_related: userComment, rating } = record;

    if (userOwner) {
      const { first_name, last_name, phone_number } = userOwner;
      owner = `${first_name} ${last_name}, ${phone_number}`;
    }
    if (userReciever) {
      const { first_name, last_name, phone_number, title: userTitle } = userReciever;
      reciever_first_name = first_name;
      reciever_last_name = last_name;
      reciever_number = phone_number;
      title = userTitle;
      category = userReciever.category_id;
      subcategory = userReciever.subcategory_id;
      services = userReciever.services && userReciever.services.map((service: any) => service.id)
    }

    if (userComment) {
      comment = userComment.comment;
    }

    const data = {
      ...record,
      owner,
      reciever_first_name,
      reciever_last_name,
      reciever_number,
      title,
      category,
      subcategory,
      comment,
      rating,
      services,
      key: record.id
    };
    return data;
  });
};
