import React, { useState, useEffect } from 'react';
import { PageHeader, Table, Button, Popconfirm, Space, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { createCategory, deleteCategory, fetchCategories, editCategory } from '../../../redux/categories/actions';
import CategoriesModalForm from '../../../components/CategoriesModalForm';
import Avatar from 'antd/lib/avatar/avatar';
import categoriesConstants from 'redux/categories/constants';
import { getImageCatIconUrl } from "../../../utils/getImageCatIconUrl";

const { PAGE_SIZE_OPTIONS, PAGE_SIZE } = categoriesConstants;

function Categories() {
  const dispatch = useDispatch();
  const [filters] = useState({
    nested: true,
  });
  const { loading, data } = useSelector((state: any) => state.categories);

  // On first load, fetch data
  useEffect(() => {
    dispatch(fetchCategories(filters));
  }, [dispatch, filters]);

  const createItem = (data: { name: string; image: string }, callback: any) => {
    dispatch(
      createCategory(data, () => {
        callback();
        dispatch(fetchCategories(filters));
      })
    );
  };

  const editItem = (id: string, data: { name: string; image: string }, callback: any) => {
    dispatch(
      editCategory(id, data, () => {
        callback();
        dispatch(fetchCategories(filters));
      })
    );
  };

  const deleteItem = (id: string) => {
    dispatch(
      deleteCategory(id, () => {
        message.success('Category deleted successfully');
      })
    );
  };

  return (
    <div>
      <PageHeader
        title='Category'
        ghost={false}
        extra={[
          <CategoriesModalForm
            openButtonText='Create'
            modalTitle='Create category'
            successMessage='Category created successfully'
            onConfirm={createItem}
            categories={data}
          />,
        ]}
      />
      <div className='content_container'>
        <Table
          dataSource={data}
          loading={loading}
          rowKey='id'
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          columns={[
            {
              title: 'Image',
              dataIndex: 'image',
              key: 'image',
              render: (image) => {
                return image && <Avatar src={getImageCatIconUrl(image)} />
              },
            },
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (row) => (
                <Space>
                  <CategoriesModalForm
                    openButtonText='Edit'
                    modalTitle='Edit category'
                    successMessage='Categoria edited successfully'
                    itemId={row.id}
                    onConfirm={editItem}
                    categories={data}
                    data={row}
                  />
                  <Popconfirm title='Confirm' okText='Yes' cancelText='No' onConfirm={() => deleteItem(row.id)}>
                    <Button danger>Delete</Button>
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Categories;
