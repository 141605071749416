import adminConstants from "./constants";
import adminServices from './services'

const {
  SET_LOADING_ADMINS,
  SET_ADMINS,
  SET_CREATING_ADMIN,
  SET_CREATED_ADMIN,
	SET_DELETING_ADMIN,
	REMOVE_ADMIN,
} = adminConstants;

const setLoadingAdmins = (isLoadingAdmins: boolean) => ({ type: SET_LOADING_ADMINS, isLoadingAdmins });
const setAdmins = (admins: any) => ({ type: SET_ADMINS, admins });

export const fetchAllAdmins = (filters?: any) => {
    return (dispatch: any) => {
        dispatch(setLoadingAdmins(true));
				adminServices.fetchAll(filters).then((res) => {
					dispatch(setLoadingAdmins(false));
					return dispatch(setAdmins(res.data.admins))
				});
    }
};


const setCreatingAdmin = (isCreatingAdmin: boolean) => ({ type: SET_CREATING_ADMIN, isCreatingAdmin });
const addAdmin = (newAdmin: boolean) => ({ type: SET_CREATED_ADMIN, newAdmin });

export const createAdmin = (email: string, password: string, callback?: any) => {
    return (dispatch: any) => {
        dispatch(setCreatingAdmin(true));
				adminServices.createAdmin(email, password).then((res) => {
					dispatch(setCreatingAdmin(false));
					const { admin } = res.data;
					if (callback) callback();
					return dispatch(addAdmin(admin));
				});
    }
};


const setDeletingAdmin = (isDeletingAdmin: boolean) => ({ type: SET_DELETING_ADMIN, isDeletingAdmin });
const removeAdmin = (deletedAdminId: string) => ({ type: REMOVE_ADMIN, deletedAdminId });

export const deleteAdmin = (id: string, callback: any) => {
    return (dispatch: any) => {
        dispatch(setDeletingAdmin(true));
				adminServices.deleteAdmin(id).then((res) => {
					dispatch(setDeletingAdmin(false));
					if (callback) callback();
					return dispatch(removeAdmin(id));
				});
    }
};
