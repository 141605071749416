import usersService from './services';
import usersConstants from './constants';

const { SET_LOADING_USERS } = usersConstants;

export const setLoadingUsers = (isLoadingUsers: boolean) => ({ type: SET_LOADING_USERS, isLoadingUsers });

export const fetchAllUsers = (query?: any) => {
  return usersService.fetchAll().catch(() => []);
};

export const fetchByNumber = (phoneNumber: string) => {
  return usersService.fetchByNumber(phoneNumber).catch(() => null);
};

export const deleteUser = (id: string) => {
  return usersService.delete(id);
};

export const editUser = (id: string, data: any) => {
  const json = JSON.stringify(Object.fromEntries(data));
  console.log(json);
  return usersService.edit(id, data);
};

export const fetchUserRecommendations = (id: string) => {
  return usersService.getRecommendations(id);
};
