import React, { useEffect } from 'react';
import { PageHeader, Table, Button, Popconfirm, Space, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAllAdmins, createAdmin, deleteAdmin } from '../../../redux/admins/actions';
import AdminModalForm from '../../../components/AdminModalForm';
import { DeleteOutlined } from '@ant-design/icons';
import adminsConstants from 'redux/admins/constants';

const { PAGE_SIZE_OPTIONS, PAGE_SIZE } = adminsConstants;

function Users() {
  const dispatch = useDispatch();
  const { loading, data, creating } = useSelector((state: any) => state.admins);

  // On first load, fetch data
  useEffect(() => {
    dispatch(fetchAllAdmins());
  }, [dispatch]);

  const createItem = (data: { email: string; password: string }, callback: any) => {
    const { email, password } = data;
    dispatch(createAdmin(email, password, callback));
  };

  const deleteItem = (id: string) => {
    dispatch(
      deleteAdmin(id, () => {
        message.success('Admin deleted successfully');
      })
    );
  };

  return (
    <div>
      <PageHeader
        title='Admins'
        ghost={false}
        extra={[
          <AdminModalForm
            openButtonText='Create'
            modalTitle='Create administrator'
            successMessage='Administrator created successfully'
            onConfirm={createItem}
            confirmLoading={creating}
          />,
        ]}
      />
      <div className='content_container'>
        <Table
          dataSource={data}
          loading={loading}
          rowKey='id'
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          columns={[
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (row) => (
                <Space>
                  <Popconfirm title='Confirm' okText='Yes' cancelText='No' onConfirm={() => deleteItem(row.id)}>
                    <Button type='primary' danger shape='round' icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Users;
