import React, { useEffect, useState } from 'react';
import { PageHeader, Table, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import statsConstants from 'redux/stats/constants';
import { fetchStats, setLoadingStats } from 'redux/stats/actions';

const { PAGE_SIZE_OPTIONS, PAGE_SIZE } = statsConstants;
const { Search } = Input;
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a: any, b: any) => {
      if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
        return -1;
      }
      if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
        return 1;
      }
      if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) {
        return -1;
      }
      if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    render: (text: any, record: any) => {
      const firstName = record.first_name;
      const lastName = record.last_name;
      return `${firstName} ${lastName}`;
    },
  },
  {
    title: 'Phone number',
    dataIndex: 'phone_number',
    key: 'phone_number',
    ellipsis: true,
    sorter: (a: any, b: any) => parseInt(a.phone_number) - parseInt(b.phone_number),
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: (a: any, b: any) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    render: (text: any, record: any) => record.title,
  },
  {
    title: 'Views',
    dataIndex: 'views',
    key: 'views',
    sorter: (a: any, b: any) => parseInt(a.business.views) - parseInt(b.business.views),
    render: (text: any, record: any) => record.business.views,
  },
  {
    title: 'Calls',
    dataIndex: 'calls',
    key: 'calls',
    sorter: (a: any, b: any) => parseInt(a.business.calls) - parseInt(b.business.calls),
    render: (text: any, record: any) => record.business.calls,
  },
  {
    title: 'Messages',
    dataIndex: 'messages',
    key: 'messages',
    sorter: (a: any, b: any) => parseInt(a.business.messages) - parseInt(b.business.messages),
    render: (text: any, record: any) => record.business.messages,
  },
  {
    title: 'Shares',
    dataIndex: 'shares',
    key: 'shares',
    sorter: (a: any, b: any) => parseInt(a.business.shares) - parseInt(b.business.shares),
    render: (text: any, record: any) => record.business.shares,
  },
];

function Stats() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { loading } = useSelector((state: any) => state.stats);
  const [filteredStats, setFilteredStats] = useState([]);
  const [load, setLoad] = useState(true);

  const fetchAllStats = () => {
    setLoad(true);
    dispatch(setLoadingStats(true));
    fetchStats().then((data: any) => {
      if (data?.data?.stats) {
        setFilteredStats(data.data.stats);
        console.log(data.data.stats);
        setData(data.data.stats);
        dispatch(setLoadingStats(false));
        setLoad(false);
      }
    });
  };

  useEffect(() => {
    fetchAllStats();
  }, [dispatch]);

  const onSearch = (value: any) => {
    if (!value.length) fetchAllStats();
    const lowerCaseValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const filteredData = data.filter(
      (row: any) =>
        `${row.first_name} ${row.last_name}`.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(lowerCaseValue)
        
    );
    setFilteredStats(filteredData);

  };
  const onChange = (e: any) => {
    const value = e.target.value;
    if (!value.length) fetchAllStats();
  };

  return (
    <div>
      <PageHeader title='Stats' ghost={false} 
      extra={[
        <Search 
          placeholder="Search user" 
            enterButton 
            allowClear 
            onSearch={onSearch} 
            style={{ width: 350 }} 
            onChange={onChange} 
        />
      ]}
      />
      <div className='content_container'>
        <Table
          columns={columns}
          dataSource={filteredStats}
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          loading={loading || load}
        />
      </div>
    </div>
  );
}

export default Stats;