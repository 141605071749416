import recommendationsService from './services';
import recommendationsConstants from './constants';

const { SET_LOADING_RECOMMENDATIONS, SET_RECOMMENDATIONS } = recommendationsConstants;

export const setLoadingRecommendations = (isLoadingRecommendations: boolean) => ({ type: SET_LOADING_RECOMMENDATIONS, isLoadingRecommendations });
export const setRecommendations = (recommendations: any) => ({ type: SET_RECOMMENDATIONS, recommendations });

export const fetchAllRecommendations = (query?: any) => {
  return recommendationsService.fetchAll().catch(() => []);
};

export const acceptRecommendations = (recommendations: any) => {
  return recommendationsService.accept(recommendations).catch((err: any) => err);
};

export const cancelRecommendations = (recommendations: any) => {
  return recommendationsService.cancel(recommendations).catch((err: any) => err);
};

export const editRecommendation = (recommendation: any) => {
  return recommendationsService.edit(recommendation).catch((err: any) => err);
};
