import React, { useState, useEffect } from 'react';
import { PageHeader, Table, Button, Input, Popconfirm, Space, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { createService, deleteService, fetchServices, editService, fetchAllServices } from '../../../redux/services/actions';
import { fetchCategories } from '../../../redux/categories/actions';
import { fetchSubcategories } from '../../../redux/subcategories/actions';
import ServicesModalForm from '../../../components/ServicesModalForm';
import servicesConstants from 'redux/services/constants';

const { PAGE_SIZE_OPTIONS, PAGE_SIZE } = servicesConstants;
const { Search } = Input;
function Services() {
  const dispatch = useDispatch();
  const [filters] = useState({
    nested: true,
  });
  const { loading, data } = useSelector((state: any) => state.services);
  const categories = useSelector((state: any) => state.categories.data);
  const subcategories = useSelector((state: any) => state.subcategories.data);
  const [uniqueNamesSubcategories, setUniqueNamesSubcategories] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    fetchServicesAll();
  }, [dispatch]);
  // On first load, fetch data
  useEffect(() => {
    fetchServicesAll();
    dispatch(fetchCategories());
    dispatch(fetchSubcategories());
    dispatch(fetchServices(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    const filteredSubcategories = subcategories.filter(
      (subcategory: any, index: any, self: any) => index === self.findIndex((subcategoryItem: any) => subcategoryItem.name === subcategory.name)
    );
    setUniqueNamesSubcategories(filteredSubcategories);
  }, [subcategories]);

  const createItem = (data: { name: string; image: string; subcategory_id: string; category_id: string }, callback: any) => {
    dispatch(
      createService(data, () => {
        callback();
        dispatch(fetchServices(filters));
      })
    );
  };

  const editItem = (id: string, data: { name: string; image: string; subcategory_id: string; category_id: string }, callback: any) => {
    dispatch(
      editService(id, data, () => {
        callback();
        dispatch(fetchServices(filters));
      })
    );
  };

  const deleteItem = (id: string) => {
    dispatch(
      deleteService(id, () => {
        message.success('Servicio borrado con exito');
      })
    );
  };

  const onSearch = (value: any) => {
    if (!value.length) fetchServicesAll();
    const lowerCaseValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const filteredData = data.filter(
      (row: any) =>
        `${row.name}`.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(lowerCaseValue)
        
    );
    setFilteredServices(filteredData);

  };
  const onChange = (e: any) => {
    const value = e.target.value;
    if (!value.length) fetchServicesAll();
  };

  const fetchServicesAll = () => {
    setLoad(true);
    fetchAllServices().then((data: any) => {
      if (data?.data?.services) {
        setFilteredServices(data.data.services);
        setLoad(false);
      }
    });
  };

  return (
    <div>
      <PageHeader
        title='Services'
        ghost={false}
        extra={[
          <Search 
            placeholder="Search services" 
              enterButton 
              allowClear 
              onSearch={onSearch} 
              style={{ width: 350 }} 
              onChange={onChange} 
          />,
          <ServicesModalForm
            openButtonText='Create'
            modalTitle='Create service'
            successMessage='Service created successfully'
            onConfirm={createItem}
            categories={categories}
            subcategories={subcategories}
          />,
        ]}
      />
      <div className='content_container'>
        <Table
          dataSource={filteredServices}
          loading={loading || load}
          rowKey='id'
          pagination={{ defaultPageSize: PAGE_SIZE, pageSizeOptions: PAGE_SIZE_OPTIONS, showSizeChanger: true }}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              sorter: (a: any, b: any) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              },
            },
            {
              title: 'Category',
              dataIndex: 'category',
              key: 'category',
              render: (record) => record && record.name,
              onFilter: (value: any, record: any) => {
                return record.category && record.category.name && record.category.name.indexOf(value) === 0;
              },
              filters: categories && categories.map((category: any) => ({ text: category.name, value: category.name })),
            },
            {
              title: 'Subcategory',
              dataIndex: 'subcategory',
              key: 'subcategory',
              render: (record) => record && record.name,
              onFilter: (value: any, record: any) => {
                return record.subcategory && record.subcategory.name && record.subcategory.name.indexOf(value) === 0;
              },
              filters:
                uniqueNamesSubcategories && uniqueNamesSubcategories.map((subcategory: any) => ({ text: subcategory.name, value: subcategory.name })),
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (record) => (
                <Space>
                  <ServicesModalForm
                    openButtonText='Edit'
                    modalTitle='Edit service'
                    successMessage='service edited successfully'
                    itemId={record.id}
                    onConfirm={editItem}
                    categories={categories}
                    subcategories={subcategories}
                    data={record}
                  />
                  <Popconfirm title='Confirm' okText='Yes' cancelText='No' onConfirm={() => deleteItem(record.id)}>
                    <Button danger>Delete</Button>
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Services;