import api from "../../helpers/api";

const authServices = {
	me: () => {
		return api.get("/admin/me");
	},
	login: (email: string, password: string, remember?: boolean) => {
		return api.post("/admin/login", { email, password }, { params: { remember } });
	},
	logout: () => {
		return api.get("/admin/logout");
	},
	requestRecovery: (email: string) => {
		return api.post('/admin/recovery', { email });
	},
	checkRecovery: (token: string) => {
		return api.get('/admin/recovery/' + token);
	},
	postRecovery: (token: string, password: string) => {
		return api.put('/admin/recovery', { token, password });
	}
}

export default authServices;