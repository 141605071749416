import recommendationsConstants from './constants';

const { SET_LOADING_RECOMMENDATIONS, SET_RECOMMENDATIONS } = recommendationsConstants;

const initialState = {
  loading: false,
  data: [],
};

const recommendationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING_RECOMMENDATIONS: {
      return {
        ...state,
        loading: action.isLoadingRecommendations,
      };
    }
    case SET_RECOMMENDATIONS: {
      return {
        ...state,
        data: action.recommendations
      }
    }
    default: {
      return state;
    }
  }
};

export default recommendationsReducer;
