import api from "../../helpers/api";


const adminsServices = {
	fetchAll: (filters: any) => {
		return api.get('/admin', { params: { ...filters } });
	},
	createAdmin: (email: string, password: string) => {
		return api.post('/admin', { email, password });
	},
	deleteAdmin: (id: string) => {
		return api.delete('/admin/' + id);
	}

}

export default adminsServices;