import React, { useEffect } from 'react';
import { Route, Switch, useLocation, useHistory } from "react-router-dom";

import Login from "./login/";
import Dashboard from "./dashboard";
import RecoverRequest from './recover/request';
import RecoverToken from './recover/token';

import { useSelector, useDispatch } from 'react-redux';
import { fetchMe } from '../redux/auth/actions';
import { Spin } from 'antd';

const loggedOutRoutes = ['/login', '/recover/request', '/recover/'];

const AppRouter = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, checkingSession } = useSelector((state: any) => state.auth);

  useEffect(() => {
    // Check if user has session
    dispatch(fetchMe());
  }, [dispatch])

  useEffect(() => {
    if (checkingSession === false) {
      const { pathname } = location;
      if (user) {
        // If user is logged in and outside dashboard, redirect in
        const redirect = loggedOutRoutes.some(route => pathname.startsWith(route));
        if (redirect && location.pathname !== "/admins") history.push("/admins");
      } else {
        // If user is logged out and inside dashboard, redirect out
        console.log(pathname)
        const redirect = loggedOutRoutes.every(route => !pathname.startsWith(route));
        if (redirect && location.pathname !== "/login") history.push("/login");
      }
    }
  }, [location, checkingSession, history, user]);

  if (checkingSession) {
    const spinContainerStyle = {
      display: "flex",
      alignItems:"center",
      justifyContent: "center",
      height: "100vh"
    }
    return (
      <div style={spinContainerStyle}>
        <Spin tip="Loading..."/>
      </div>
    )
  }
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/recover/request" component={RecoverRequest} />
      <Route exact path="/recover/:token" component={RecoverToken} />
      <Route path="/" component={Dashboard} />
    </Switch>
  );
}

export default AppRouter;
