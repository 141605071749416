export const spacesImages = 'https://recomiend-storage.nyc3.digitaloceanspaces.com';

export const getImageCustomHeaderUrlCat = (path: string) => {
    console.log(path);
    if (!path) {
        return '';
    }

    if (!path.includes('public')) {
        return `${spacesImages}/web_users_header_background_by_cat/${path}`;
    } else {
        return `${process.env.REACT_APP_API_URL}/${path}`;
    }
} 