import axios from "axios";

export const DOMAIN_API = process.env.REACT_APP_BASE_DOMAIN_API;

const instance = axios.create({
  baseURL: 'https://domain.recomiend.app/api',
  responseType: "json",
});

instance.defaults.headers.common['Authorization'] = 'Bearer 4bc0tvwWdsduXb6TNObEr0LENeWXKjEh';

export default instance;