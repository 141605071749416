import React, { useState } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import styles from '../styles.module.scss';
import { getImageCatIconUrl } from "../../utils/getImageCatIconUrl";
import { getImageCustomHeaderUrlCat } from "../../utils/getImageCustomHeaderUrlCat";
import { getImageCustomHeaderUrlCatMob  } from "../../utils/getImageCustomHeaderUrlCatMobile";

function CategoriesModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  successMessage?: string;
  categories?: any[];
  itemId?: string;
  data?: any;
}) {
  const [imageFile, setImageFile] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [imageFileCustomHeader, setImageFileCustomHeader] = useState('');
  const [imageURLCustomHeader, setImageURLCustomHeader] = useState('');
  const [imageFileCustomHeaderMobile, setImageFileCustomHeaderMobile] = useState('');
  const [imageURLCustomHeaderMobile, setImageURLCustomHeaderMobile] = useState('');
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { modalTitle, openButtonText, onConfirm, successMessage, itemId, data } = props;

  const openModal = () => {
    form.setFieldsValue(data);
    const initialImageUrl = data && getImageCatIconUrl(data.image);
    setImageURL(initialImageUrl);
    const initialImageCustomHeaderUrlCat = data && getImageCustomHeaderUrlCat(data.imagedefaultheader);
    setImageURLCustomHeader(initialImageCustomHeaderUrlCat);
    const initialImageCustomHeaderUrlCatMob = data && getImageCustomHeaderUrlCatMob(data.imagedefaultheadermob);
    setImageURLCustomHeaderMobile(initialImageCustomHeaderUrlCatMob);
    setVisible(true);
  };
  const onCancel = () => setVisible(false);
  const onSuccess = () => {
    setVisible(false);
    form.resetFields();
    message.success(successMessage || 'Exit');
  };
  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        // Generate form data
        var formData = new FormData();
        // Get image into form data
        if (imageFile) {
          formData.append('image', imageFile);
        }
        if (imageFileCustomHeader) {
          formData.append('imagedefaultheader', imageFileCustomHeader);
        }
        if (imageFileCustomHeaderMobile) {
          formData.append('imagedefaultheadermob', imageFileCustomHeaderMobile);
        }
        for (var key in values) {
          if (values[key]) {
            formData.append(key, values[key]);
          }
        }

        // Run function
        if (itemId) {
          onConfirm(itemId, formData, onSuccess);
        } else {
          onConfirm(formData, onSuccess);
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };
  const onImageChange = (e: any) => {
    setImageFile(e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]));
  };
  const onImageChangeCustomHeader = (e: any) => {
    setImageFileCustomHeader(e.target.files[0]);
    setImageURLCustomHeader(URL.createObjectURL(e.target.files[0]));
  };
  const onImageChangeCustomHeaderMobile = (e: any) => {
    setImageFileCustomHeaderMobile(e.target.files[0]);
    setImageURLCustomHeaderMobile(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <>
      <Button type='primary' onClick={openModal}>
        {openButtonText}
      </Button>
      <Modal title={modalTitle} visible={visible} onOk={onOk} onCancel={onCancel}>
        <Form layout='vertical' form={form}>
          {/* NAME */}
          <Form.Item label='Name' name='name' rules={[{ required: true, message: 'Please enter the name' }]}>
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item label='Google Category' name='google_category' rules={[{ required: true, message: 'Please enter the name of google category' }]}>
            <Input placeholder='Google Category' />
          </Form.Item>
          {/* IMAGE */}
          <Form.Item label='Image' name='image'>
            <div className={styles.imagePreviewContainer}>
              {imageURL ? <img className={styles.imagePreview} src={imageURL} alt='category' /> : null}
            </div>
            <Input type='file' onChange={onImageChange} />
          </Form.Item>
          <Form.Item label='Image Defaul Header Public Web' name='imagedefaultheader'>
            <div className={styles.imagePreviewContainer}>
              {imageURLCustomHeader ? <img className={styles.imagePreview} src={imageURLCustomHeader} alt='category' /> : null}
            </div>
            <Input type='file' onChange={onImageChangeCustomHeader} />
          </Form.Item>
          <Form.Item label='Image Defaul Header Public Web Mobile' name='imagedefaultheadermob'>
            <div className={styles.imagePreviewContainer}>
              {imageURLCustomHeaderMobile ? <img className={styles.imagePreview} src={imageURLCustomHeaderMobile} alt='category' /> : null}
            </div>
            <Input type='file' onChange={onImageChangeCustomHeaderMobile} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default CategoriesModalForm;
