import React, { useState } from 'react';
import { Button, Modal, Form, Input, message, Select } from 'antd';

function SubcategoriesModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  successMessage?: string;
  categories?: any[];
  subcategories?: any[];
  itemId?: string;
  data?: any;
}) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { modalTitle, openButtonText, onConfirm, successMessage, itemId, data } = props;
  const { categories } = props;

  const openModal = () => {
    form.setFieldsValue(data);
    setVisible(true);
  };
  const onCancel = () => setVisible(false);
  const onSuccess = () => {
    setVisible(false);
    form.resetFields(['name', 'category_id']);
    message.success(successMessage || 'Exit');
  };
  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        // Generate form data
        var formData = new FormData();
        for (let key in values) {
          if (values[key]) {
            formData.append(key, values[key]);
          }
        }

        // Run function
        if (itemId) {
          onConfirm(itemId, formData, onSuccess);
        } else {
          onConfirm(formData, onSuccess);
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <>
      <Button type='primary' onClick={openModal}>
        {openButtonText}
      </Button>
      <Modal title={modalTitle} visible={visible} onOk={onOk} onCancel={onCancel}>
        <Form layout='vertical' form={form}>
          {/* NAME */}
          <Form.Item label='Name' name='name' rules={[{ required: true, message: 'Please enter the name' }]}>
            <Input placeholder='Name' />
          </Form.Item>
          {/* PARENT */}
          <Form.Item label='Category' name='category_id' rules={[{ required: true, message: 'Please choose the category' }]}>
            <Select placeholder='Category'>
              {/* DONT SHOW ITSELF */}
              {categories &&
                categories.map((category: any) => (
                  <Select.Option value={category.id} key={category.id}>
                    {category.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default SubcategoriesModalForm;
