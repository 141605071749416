import React, { useState } from 'react';
import { Button, Modal, Form, Input, message } from "antd";

function AdminModalForm(props: {
  modalTitle: string;
  openButtonText: string;
  onConfirm: any;
  confirmLoading: boolean;
  successMessage?: string
}) {
  const {
    modalTitle,
    openButtonText,
    onConfirm,
    confirmLoading,
    successMessage,
  } = props;

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const openModal = () => setVisible(true);
  const onCancel = () => setVisible(false);
	const onSuccess = () => {
    setVisible(false);
    form.resetFields();
		message.success(successMessage || "Exit");
	}

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        onConfirm(values, onSuccess);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Button type="primary" onClick={openModal}>
        {openButtonText}
      </Button>
      <Modal
        title={modalTitle}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter your email" },
              { type: "email", message: "Enter a valid email" },
            ]}
          >
            <Input placeholder="Email" disabled={confirmLoading} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please enter your password" },
            ]}
          >
            <Input
              type="password"
              placeholder="Password"
              disabled={confirmLoading}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AdminModalForm;