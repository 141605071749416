import React from 'react'
import { Form, Input, Checkbox, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import styles from "./styles.module.scss";
import logo from '../../assets/logo-recomiendapp-white.png';
import { loginUser } from "../../redux/auth/actions";


function Login() {
  const { loggingIn, loginError } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  
  const onLoginSuccess = () => {
    history.push("/admins")
  }

  const onFinish = (values: { email: string, password: string, remember: boolean }) => {
    const { email, password, remember } = values;
    const data = { email, password };
    dispatch(loginUser(data, remember, onLoginSuccess));
  };

	return (
    <div className={styles.login_form_container}>
      <img src={logo} alt="Logo" className={styles.login_form_logo} />
      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Enter a valid email" }
          ]}
          validateStatus={loginError && "error"}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
            disabled={loggingIn}
            />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please enter your password" }]}
          validateStatus={loginError && "error"}
          help={loginError || null}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            disabled={loggingIn}
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox disabled={loggingIn}>Remember me</Checkbox>
          </Form.Item>

          <Link className={styles.login_form_forgot} to="/recover/request">
            Forgot your password?
          </Link>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login_form-submit"
            disabled={loggingIn}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;

