export const spacesImages = 'https://recomiend-storage.nyc3.digitaloceanspaces.com';

export const getImageCatIconUrl = (path: string) => {
    console.log(path);
    if (!path) {
        return '';
    }

    if (!path.includes('public')) {
        return `${spacesImages}/categories_icons/${path}`;
    } else {
        return `${process.env.REACT_APP_API_URL}/${path}`;
    }
} 